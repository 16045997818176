
import { startLoading, stopLoading } from "../../loadingstate/actions";
import { newsApi } from "../utils/api";
import { newsConstants } from "./action-types";


const getAllBlogsSuccess = (data) => {
  return {
    type: newsConstants.GET_ALL_BLOGS_SUCCESS,
    data: data,
  };
};

const getAllBlogsErr = (err) => {
  return {
    type: newsConstants.GET_ALL_BLOGS_ERROR,
    valuesError: err,
  };
};

const getAllBlogsEvent = (page) => {
  return (dispatch) => {
    dispatch(startLoading());
    newsApi
      .getAllBlogs(page)
      .then((response) => {
        dispatch(getAllBlogsSuccess(response.data.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getAllBlogsErr(err));
        dispatch(stopLoading());
      });
  };
};

const getBlogByIdSuccess = (data) => {
  return {
    type: newsConstants.GET_BLOG_BY_ID_SUCCESS,
    data: data,
  };
};

const getBlogByIdErr = (err) => {
  return {
    type: newsConstants.GET_BLOG_BY_ID_ERROR,
    valuesError: err,
  };
};

const getBlogByIdEvent = (id) => {
  return (dispatch) => {
    dispatch(startLoading());
    newsApi
      .getBlogById(id)
      .then((response) => {
        dispatch(getBlogByIdSuccess(response.data.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getBlogByIdErr(err));
        dispatch(stopLoading());
      });
  };
};

// const clearBlog = () => {
//   return {
//       type: newsConstants.CLEAR_BLOGS,
//   };
// }


export const newsAction = {
  getAllBlogsEvent,
  getBlogByIdEvent
};
