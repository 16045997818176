import { petMarketingConstants } from "./action-types";

const initialState = {
    allPetsMarketing: false,
    allPetsMarketingErr: false,
    totalPetsMarketing: 0,
    getPetById: false,
    getPetByIdErr: false
};

const petsMarketingReducer = (state = initialState, action) => {
    switch (action.type) {
        case petMarketingConstants.GET_ALL_PETS_SUCCESS:
            return {
                ...state,
                allPetsMarketing: action.data.currentPage === 1
                    ? action.data.docs
                    : [...state.allPetsMarketing, ...action.data.docs],
                allPetsMarketingErr: false,
                totalPetsMarketing: action.data.totalDocs
            }
        case petMarketingConstants.GET_ALL_PETS_ERROR:
            return {
                ...state,
                allPetsMarketing: false,
                allPetsMarketingErr: action.valuesError,
                totalPetsMarketing: 0
            }
        case petMarketingConstants.GET_PET_BY_ID_SUCCESS:
            return {
                ...state,
                getPetById: action.data,
                getPetByIdErr: false
            }
        case petMarketingConstants.GET_PET_BY_ID_ERROR:
            return {
                ...state,
                getPetById: false,
                getPetByIdErr: action.valuesError
            }
        default:
            return state;
    }
};
export { petsMarketingReducer };
