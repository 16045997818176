import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { petMarketingAction } from "../State/actions";
import { useParams } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import styles from "../../Common/Styles/common.module.css";
import { Col, Empty, Row } from "antd";

const PetsDescription = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { isLoading } = useSelector((str) => str.loadingReducer);
    const { getPetById } = useSelector((str) => str.petsMarketingReducer);

    console.log(getPetById, "getPetById");

    useEffect(() => {
        dispatch(petMarketingAction.getPetByIdEvent(id));
    }, []);

    if (isLoading) return <Spinner />;

    return getPetById ? (
        <>
            <div style={{ background: "black" }} className={styles.navbarmenu}></div>
            <Row justify={"center"}>
                <Col xs={22} sm={20} md={18} lg={16}>
                    <p
                        className={styles.descImg}
                        dangerouslySetInnerHTML={{
                            __html: getPetById?.description,
                        }}
                    />
                </Col>
            </Row>
        </>
    ) : (
        <>
            <div style={{ background: "black" }} className={styles.navbarmenu}></div>
            <Empty style={{margin:"2rem"}}/>
        </>
    );
};

export default PetsDescription;
