import React from "react";
import PhoneInput from "react-phone-input-2";
import { MailFilled } from "@ant-design/icons";
import { Button, Col, Form, Input, Layout, Row } from "antd";
import styles from "./styles/Form.module.css";
import "react-phone-input-2/lib/style.css";
const { Content } = Layout;

const ContactForm = () => {
  const { TextArea } = Input;
  return (
    <>
      <Content
        className={styles.contactWrapper}
        style={{ overflowX: "hidden" }}
        id="contact-form"
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={{ offset: 2, span: 10 }}>
            <div className={styles.formText}>
              <p
                className={styles.poppins_agile}
                style={{ padding: 0, marginTop: 0 }}
              >
                Let's Build Your Agile Team.
              </p>
              <p className={styles.experience} style={{ padding: 0 }}>
                Experience PragmaSoft -
              </p>
              <p className={styles.connect}>Connect With US Today!</p>
              <p className={styles.contact} style={{ padding: 0 }}>
                Please fill out the form or send us an email to
              </p>
              <p className={styles.contact} style={{ padding: 0 }}>
                <MailFilled /> contact@pragmasoft.in
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={{ span: 10 }}>
            <Form wrapperCol={24}>
              <Form.Item name="userName" rules={[{ required: true }]}>
                <Input placeholder="Your name*" className={styles.formInput} />
              </Form.Item>
              <Form.Item style={{ marginBottom: "0" }}>
                <Row>
                  <Col xs={24} sm={24} md={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item name="EmailAddress" rules={[{ required: true }]}>
                      <Input
                        placeholder="Email address*"
                        className={styles.formInput}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={{ span: 24 }}
                    lg={{ offset: 1, span: 11 }}
                  >
                    <Form.Item name="phoneNumber" rules={[{ required: true }]}>
                      <PhoneInput
                        countryCodeEditable={false}
                        country="in"
                        inputStyle={{
                          width: "100%",
                          padding: "8px 11px 8px 48px",
                          border: "0.5px solid rgba(0, 0, 0, 0.50)",
                          height: "45.45px",
                          borderRadius: "2px",
                        }}
                        buttonStyle={{
                          border: "0.5px solid rgba(0, 0, 0, 0.50)",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="Company" rules={[{ required: true }]}>
                <Input placeholder="Company*" className={styles.formInput} />
              </Form.Item>
              <Form.Item>
                <TextArea
                  placeholder="Tell us more about your requirements"
                  autoSize={{ minRows: 5, maxRows: 6 }}
                  className={styles.formInput}
                />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "start",
                }}
              >
                <Button className={styles.btn}>Submit</Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export { ContactForm };
