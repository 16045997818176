import { Layout, FloatButton } from "antd";
import { Route, Routes } from "react-router-dom";
import { Home } from "../Home";
import { Navbar } from "../Navbar";
import styles from "./Styles/App.module.css";
import { FooterComp } from "../Footer/FooterComp";
import { UpOutlined } from "@ant-design/icons";
import PrivacyPolicy from "../Static/PrivacyPolicy";
import TermsAndConditions from "../Static/TermsAndConditions";
import ReservationPolicy from "../Static/ReservationPolicy";
import Disclaimer from "../Static/Disclaimer";
import RefundCancellationPolicy from "../Static/RefundCancellationPolicy";
import Help from "../Help/Help";
import { News } from "../News";
import { NotFound } from "../Common/Components/NotFound";
import NewsDescription from "../News/Components/NewsDescription";
import { Suspense } from "react";
import Spinner from "../Spinner/Spinner";
import ContactUs from "../ContactForm/Components/ContactUs";
import { Pets } from "../Pets";
import PetsDescription from "../Pets/Components/PetsDescription";
import AboutUs from "../Static/Components/AboutUs";

const { Content } = Layout;

function App() {
  const customIcon = <UpOutlined className={styles.backToTopIcon} />;
  return (
    <Suspense fallback={<Spinner />}>
      <Layout className={styles.layoutStyle}>
        <Navbar />
        <Content className={styles.layoutStyle}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route exact path="/termsOfUse" element={<TermsAndConditions />} />
            <Route exact path="/reservationPolicy" element={<ReservationPolicy />} />
            <Route exact path="/refundCancellationPolicy" element={<RefundCancellationPolicy />} />
            <Route exact path="/disclaimer" element={<Disclaimer />} />
            <Route exact path="/faqs" element={<Help />} />
            <Route exact path="/news" element={<News />} />
            <Route exact path="/pets" element={<Pets />} />
            <Route exact path="/pet/:id" element={<PetsDescription />} />
            <Route exact path="/contactUs" element={<ContactUs />} />
            <Route exact path="/:id" element={<NewsDescription />} />
            <Route exact path="/aboutUs" element={<AboutUs />} />
            <Route path="*" element={<NotFound status={"404"} continueTo={"/"} subTitle={"Sorry, the page you visited does not exist."} />} />
          </Routes>
        </Content>
        <div className={styles.backto}>
          <FloatButton.BackTop
            shape="circle"
            icon={customIcon}
            rootClassName={styles.backtotop}
            style={{ color: "white" }}
          ></FloatButton.BackTop>
        </div>
        <FooterComp />
      </Layout>
    </Suspense>
  );
}

export { App };
