import React from "react";
import { Layout } from "antd";
import { v4 as uuidv4 } from "uuid";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../Assets/9thsection/image1.png";
import image2 from "../../Assets/9thsection/image2.png";
import image3 from "../../Assets/9thsection/image3.png";
import styles from "../Styles/petKnowledge.module.css";
import PetKnowledgeSlide from "./PetKnowledgeSlide";
import { useMediaQuery } from "react-responsive";

const { Content } = Layout;

const PetKnowledge = () => {
  const isLargeScreen = useMediaQuery({ maxWidth: 992 });
  const isTabletScreen = useMediaQuery({ maxWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 576 });

  const carouselData = [
    {
      image: image1,
      title:
        "Yes! in India there is only 50% of pet owners really cares about their pets.",
    },
    {
      image: image2,
      title: "Pets get unhygienic & getting severe harms, due to  foods.",
    },
    {
      image: image3,
      title:
        "Lack of caring makes them anger. It leads to feel like stress, scratches, barking all the time & sometimes bites.",
    },
  ];

  const CustomPrevArrow = ({
    currentSlide,
    slideCount,
    children,
    ...props
  }) => (
    <div
      {...props}
      style={{
        position: "absolute",
        // left: isLargeScreen ? "130px" : isTabletScreen ? "100px" : "190px",
        left: isMobile
          ? "55px"
          : isTabletScreen
          ? "85px"
          : isLargeScreen
          ? "130px"
          : "190px",
        transform: "translateY(-50%)",
        zIndex: 1,
        background: "#3A5FA0",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      &lt;
    </div>
  );

  const CustomNextArrow = ({
    currentSlide,
    slideCount,
    children,
    ...props
  }) => (
    <div
      {...props}
      style={{
        position: "absolute",
        right: isMobile
          ? "55px"
          : isTabletScreen
          ? "85px"
          : isLargeScreen
          ? "130px"
          : "190px",
        transform: "translateY(-50%)",
        zIndex: 1,
        background: "#3A5FA0",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      &gt;
    </div>
  );

  const settings = {
    dots: false,
    fade: true,
    arrows: true,
    infinite: true,
    centerMode: true,
    speed: 300,
    slidesToShow: 1,
    autoplay: false,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    focusOnChange: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    useCSS: true,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: true,
          centerPadding: "40px",
          slidesToShow: 1,
          centerMode: true,
          fade: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: true,
          centerPadding: "40px",
          slidesToShow: 1,
          centerMode: true,
          fade: true,
        },
      },
    ],
  };

  return (
    <Content>
      <Slider {...settings} className={styles.slider}>
        {carouselData?.map((data) => (
          <PetKnowledgeSlide
            key={uuidv4()}
            title={data?.title}
            image={data?.image}
          />
        ))}
      </Slider>
    </Content>
  );
};

export default PetKnowledge;
