import React, { useEffect, useState } from "react";
// import AvailableIn from "./AvailableIn";
import OurProvidings from "./Components/OurProvidings";
import { Dropdown, Image, Layout, Select, Space } from "antd";
// import TheyNeedCare from "./TheyNeedCare";
import GetInTouch from "./GetInTouch";
import Blog from "./Blog";
import Knowthem from "./Knowthem";
import Locations from "./Locations";
import WelcomeBanner from "./Components/WelcomeBanner";
import ServiceDetailsCarousel from "./Components/ServiceDetailsCarousel";
import OnBoard from "./Components/OnBoard";
import furryfriends from "../Assets/Furryfriends.png";
import PartnerBanner from "./Components/PartnerBanner";
import MobileLaptop from "./Components/MobileLaptop";
import PrivacyReservation from "./Components/PrivacyReservation";
import PetKnowledge from "./Components/PetKnowledge";
import { language } from "../Common/Components/multilanguages";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { homeApi } from "./utils/api";
import Spinner from "../Spinner/Spinner";
// import { useTranslation } from "react-i18next";
// import Providings from "./Components/Providings";
const { Content } = Layout;

function Home() {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const { t } = useTranslation('marketingHome');

  const getData = async () => {
    try {
      setLoading(true)
      const homedata = await homeApi.getHomeDetails();
      if (homedata.data.info) {
        setData(homedata.data.info);
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout style={{ height: "100%", background: "#fff", overflowX: "hidden" }}>
      <Content style={{ background: "#fff" }}>
        <WelcomeBanner />
      </Content>
      <Content style={{ background: "#fff" }}>
        <OnBoard />
      </Content>
      <Content style={{ background: "#fff" }}>
        <PartnerBanner />
      </Content>
      <Content style={{ background: "#fff" }}>
        <Locations />
      </Content>
      <Content style={{ background: "#fff" }}>
        <Image src={furryfriends} alt="furry friends" preview={false} />
      </Content>
      <Content style={{ background: "#fff" }}>
        <MobileLaptop />
      </Content>
      {/* <Content style={{ background: "#fff" }}>
        <AvailableIn />
      </Content>
      <Content style={{ background: "#fff" }}>
        <TheyNeedCare />
      </Content> */}
      <Content style={{ background: "#fff" }}>
        <OurProvidings />
      </Content>

      <Content style={{ background: "#fff" }}>
        <ServiceDetailsCarousel />
      </Content>
      <Content style={{ background: "#fff" }}>
        <PetKnowledge />
      </Content>
      <Content style={{ background: "#fff" }}>
        <Knowthem pets={data?.pets} loading={loading}/>
      </Content>
      <Content style={{ background: "#fff" }}>
        <PrivacyReservation />
      </Content>
      <Content style={{ background: "#fff" }}>
        <Blog blogs={data?.news}/>
      </Content>
      <Content style={{ background: "#fff" }}>
        <GetInTouch />
      </Content>
    </Layout>
  );
}
export { Home };
