import { startLoading, stopLoading } from "../../loadingstate/actions";
import { helpApi } from "../utils/api";
import { helpConstants } from "./action-types";

const getQuestionByKeySuccess = (data) => {
  return {
    type: helpConstants.GET_QUESTIONS_KEY_PLATFORM_SUCCESS,
    data: data,
  }
}

const getQuestionByKeyError = (err) => {
  return {
    type: helpConstants.GET_QUESTIONS_KEY_PLATFORM_ERROR,
    valuesError: err,
  }
}

const getAllQuestionsEvent = (page, search) => {
  return (dispatch) => {
    dispatch(startLoading());
    helpApi.getAllquestions(page, search).then(response => {
      dispatch(getQuestionByKeySuccess(response.data.info));
      dispatch(stopLoading())
    })
      .catch(err => {
        dispatch(getQuestionByKeyError(err));
        dispatch(stopLoading())
      })
  }
}

export const helpAction = {
  getAllQuestionsEvent
};
