import axios from "axios";

const apiURL = process.env.REACT_APP_DEPLOYED_URL;

// get all list of pets
const getAllPets = () => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    let url = `${apiURL}/petsMarketing`;
    // if (search) url += `?search=${search}`
    // let url = `${apiURL}/getAllHelp`
    return axios.get(url, config);
}

// get pet based on id
const getPetById = (id) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/petBreed/${id}`, config);
}


export const petsApi = {
    getAllPets,
    getPetById
};