import React from "react";
import privacyPolicyImage from "../Assets/static/privacyPolicy.png";
import { Image, Typography } from "antd";
import styles from "./Styles/static.module.css";
const { Text, Title } = Typography;

const PrivacyPolicy = () => {
  return (
    <>
      <div style={{ position: "relative" }}>
        <Image
          src={privacyPolicyImage}
          alt="Privacy_policy"
          className={styles.image}
          preview={false}
        />
        <div className={styles.overlay}>
          <Text style={{ display: "block" }} className={styles.text}>
            Protecting your data
          </Text>
          <Text style={{ display: "block" }} className={styles.text}>
            with transparency, integrity, and strict confidentiality measures.
          </Text>
        </div>
      </div>
      <div style={{ margin: "3rem" }}>
        <Text className={styles.generalText}>
          Petsmitra ("we," "us," or "our") is committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, disclose, and
          safeguard your information when you use our pet services, including pet
          boarding, pet pickup and drop, pet spa, pet walking, and pet training
          services. By using our services, you agree to the practices outlined in
          this policy.
        </Text><br />
        <Title className={styles.title}>Information We Collect</Title>
        <Text className={styles.generalText}>We may collect personal and pet-related information that you provide directly to us, including:</Text>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Personal Information:</span> Name, address, phone number, email address, emergency contact information.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Pet Information:</span> Pet’s name, breed, age, medical history, vaccination records, behavior details.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Payment Information:</span> Credit/debit card information, billing address, and payment preferences.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Service Information:</span> Information about the services you request (boarding dates, pickup/drop times, spa appointments, training needs, etc.).
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Location Data:</span> If using pickup/drop services, we may collect your location details.
          </li>
        </ul>
        <Title className={styles.title}>How We Use Your Information</Title>
        <Text className={styles.generalText}>We use the information we collect in the following ways:</Text>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>To Provide Services:</span> To book and manage services such as pet boarding, pet walking, spa, and training.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>To Communicate:</span> To send you confirmations, updates, and other information related to your pet’s services.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Payment Processing:</span> To process payments securely.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>For Safety and Health:</span> To ensure the well-being of your pet by understanding their medical history, vaccination status, and behavioral needs.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>For Marketing Purposes:</span> We may send promotional emails and offers. You can opt out of these communications at any time.
          </li>
        </ul>
        <Title className={styles.title}>How We Share Your Information</Title>
        <Text className={styles.generalText}>We may share your information in the following ways:</Text>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>With Service Providers:</span>  We may share your information with third-party service providers (e.g., payment processors, veterinarians, or pet trainers) to facilitate our services.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Legal Requirements:</span> We may disclose information if required by law, such as in response to a subpoena or other legal request.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Business Transfers:</span>  In case of a merger, acquisition, or sale of our assets, your information may be transferred as part of that transaction.
          </li>
        </ul>
        <Title className={styles.title}>Your Rights</Title>
        <Text className={styles.generalText}>You have the following rights concerning your personal information:</Text>
        <Title className={styles.title}>How We Share Your Information</Title>
        <Text className={styles.generalText}>We may share your information in the following ways:</Text>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Access:</span> You may request access to the personal data we hold about you.

          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Correction:</span> You can request the correction of inaccurate personal information.

          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Deletion:</span> You may request the deletion of your personal data, except where we are required by law to retain it.
          </li>
        </ul>
        <Text className={styles.generalText}>To exercise any of these rights, please contact us at [your contact email].</Text>
        <Title className={styles.title}>Cookies and Tracking Technologies</Title>
        <Text className={styles.generalText}>We may use cookies and similar tracking technologies to collect information about your use of our website to improve your online experience. You can modify your browser settings to control or block cookies, but this may affect your ability to use certain features of our website.
        </Text>
        <Title className={styles.title}>Third-Party Websites</Title>
        <Text className={styles.generalText}>Our website may contain links to third-party websites. We are not responsible for the privacy practices of those websites. We encourage you to read their privacy policies when visiting their sites.
        </Text>
        <Title className={styles.title}>Changes to This Policy</Title>
        <Text className={styles.generalText}>We may update this Privacy Policy periodically to reflect changes in our practices or for legal reasons. The updated policy will be posted on our website with the “Last Updated” date. Please review the policy periodically to stay informed.
        </Text>
        <Title className={styles.title}>Contact Us</Title>
        <Text className={styles.generalText}>If you have any questions or concerns about this Privacy Policy, please contact us at:
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>PetsMitra
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>28/2A, Shakuntala Kanada Pa Haveli
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>Undri City,
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>Pune,
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>Maharashtra.
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>(+91) 8483-089-257
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>info@petsmitra.com
        </Text>
        <br />
        <br />
        <Text style={{ display: "block" }} className={styles.generalText}>Last updated: Oct 24, 2024
        </Text>
      </div>
    </>
  );
};

export default PrivacyPolicy;
