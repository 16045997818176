import React, { useState } from "react";
import BigBlogCard from "./Components/BigBlogCard";
import blog1 from "../Assets/Blog/blog1.png";
import blog2 from "../Assets/Blog/blog2.png";
import blog3 from "../Assets/Blog/blog3.png";
import blog4 from "../Assets/Blog/blog4.png";
import styles from "./Styles/blog.module.css";

const Blog = ({blogs}) => {
  // const blogs = [
  //   {
  //     id: "blog1",
  //     image: blog1,
  //     title:
  //       "Airlines that allow dogs to fly next to you in the passenger cabin",
  //     description:
  //       "In case you are a canine lover, you wouldn't need your treasured pet to go away from you. Fortunately, there are various choices for anybody touring with their pet. The foundations for taking your canine on a aircraft will depend upon which nation you are visiting and which airline you've got booked a seat with. The foundations for taking your canine on an aircraft will depend upon which nation you are visiting and which airline you've got booked a seat with.",
  //   },
  //   {
  //     id: "blog2",
  //     image: blog2,
  //     title:
  //       "Russian Dog Enthusiasts Rally to Rescue Stray Dogs from a Potential Culling",
  //     description:
  //       "Dog enthusiasts are mobilizing to rescue numerous stray dogs in Russia’s Far East following a decision by authorities to initiate euthanasia. In the Eastern Siberian town of Ulan-Ude, near the Mongolian border, 18 dogs have already been euthanized. However, a positive response from compassionate individuals has led to the relocation of 600 dogs via train to new homes or private shelters across Russia.",
  //   },
  //   {
  //     id: "blog3",
  //     image: blog3,
  //     title: "Cat Who Went Missing in New York 10 Years Ago Found Pet News",
  //     description:
  //       "Cats are recognized for being very impartial, and generally they make their very own guidelines. However not all cats can survive within the wild. One can solely think about what a younger cat should face every day when out in a crowded space.",
  //   },
  //   {
  //     id: "blog4",
  //     image: blog4,
  //     title: "Stray dogs have the natural ability to understand human gestures",
  //     description:
  //       "People domesticated canines, and over our 15,000-year relationship, we’ve bred canines to be pleasant and keen companions—in addition to expert at deciphering our feelings. Now, a brand new research reveals even stray canines—animals which have by no means lived with folks—can nonetheless perceive our gestures.",
  //   },
  // ];
  const [blogCards, setBlogCards] = useState(blogs);
  const handleClick = (clickedCard) => {
    const updatedCards = blogCards.filter((card) => card.id !== clickedCard.id);
    setBlogCards([clickedCard, ...updatedCards]);
  };

  return (
    <>
      <div id="blogs" className={styles.blogContainer}>
        <BigBlogCard blogCards={blogs} />
      </div>
    </>
  );
};

export default Blog;
