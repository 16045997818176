import {
  applyMiddleware,
  legacy_createStore as createStore,
  combineReducers,
} from "redux";
import { thunk } from "redux-thunk";
import { appReducer } from "./state/index";
import { navbarReducer } from "./Navbar";
import { loadingReducer } from "./loadingstate/reducer";
import { helpReducer } from "./Help/State/reducer";
import { newsReducer } from "./News";
import { petsMarketingReducer } from "./Pets";

const reducers = {
  appReducer,
  loadingReducer,
  helpReducer,
  navbarReducer,
  newsReducer,
  petsMarketingReducer
};

const rootReducers = combineReducers(reducers);
const store = applyMiddleware(thunk)(createStore)(rootReducers);

export { store, reducers };
