import React from 'react';
import reservationPolicyImage from '../Assets/static/reservationPolicy.png';
import { Image, Typography } from 'antd';
import styles from './Styles/static.module.css';
const { Text, Title } = Typography;

const ReservationPolicy = () => {
  return (
    <>
      <div style={{ position: 'relative' }}>
        <Image
          src={reservationPolicyImage}
          alt="Privacy_policy"
          className={styles.image}
          preview={false}
        />
        <div className={styles.overlay}>
          <Text style={{ display: "block" }} className={styles.termsofuseText}>
            Reservation Policy
          </Text>
          <Text style={{ display: "block" }} className={styles.text}>
            Making a reservation with us implies acceptance of our reservation policy.
          </Text>
        </div>
      </div>
      <div style={{ margin: "3rem" }}>
        <Text className={styles.generalText}>
          At [Your Company Name], we aim to provide the best possible care and services for your pets. To ensure a smooth experience, please review our Reservation Policy before booking any of our services, including pet boarding, pet pickup and drop, pet spa, pet walking, and pet training.
        </Text><br />
        <Title className={styles.title}>General Booking Requirements</Title>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Advance Reservations:</span> All services must be reserved in advance through our website, phone, or in person.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Pet Information:</span> Before making a reservation, we require updated information about your pet, including medical records, vaccination history, and any specific care requirements.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Vccination Records:</span> For the safety of all pets, we require proof of up-to-date vaccinations, including rabies, distemper, and bordetella. Pets without vaccination proof may not be admitted.
          </li>
        </ul>
        <Title className={styles.title}>Deposits and Payment</Title>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Deposit Requirement:</span> A non-refundable deposit of [percentage or amount] is required at the time of booking to secure your pet’s reservation for boarding or long-term services.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Payment Policy:</span> Full payment is due before or upon arrival for boarding, spa, walking, or training services. Payments for pickup and drop services can be made at the time of scheduling or at pickup.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Accepted Payment Methods:</span> We accept payments through major credit/debit cards, digital wallets, and online payment gateways.
          </li>
        </ul>
        <Title className={styles.title}>Cancellations and Refunds </Title>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Cancellation Deadline:</span> Cancellations must be made at least [insert number of hours or days] prior to the reservation date to avoid a cancellation fee. For pet boarding, cancellations made less than [insert time frame] before the scheduled drop-off will incur a [insert fee amount or percentage] cancellation fee.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>No-Show Policy:</span> If you do not cancel and fail to show up for a scheduled service, your deposit will be forfeited, and no refunds will be provided.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Refund Policy:</span> Deposits are non-refundable unless we cancel your reservation due to unforeseen circumstances. Full refunds will be issued only if cancellations are made within the required time frame, minus the deposit.
          </li>
        </ul>
        <Title className={styles.title}>Changes to Reservations</Title>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Modification of Reservation:</span>If you need to modify your reservation, please contact us at least [insert number of hours or days] in advance. We will do our best to accommodate changes based on availability.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Early Pick-Up:</span> For pet boarding, if you choose to pick up your pet earlier than the scheduled date, you will not be refunded for the unused days of service.
          </li>
        </ul>
        <Title className={styles.title}>Late Arrival or Pickup</Title>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Late Drop-Off or Pick-Up:</span>Please adhere to the scheduled drop-off and pick-up times. Late arrivals or pickups may incur additional charges of [insert fee amount] for each hour beyond the scheduled time. For boarding, late pickups will result in an additional day’s charge.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Extended Stay:</span> If you need to extend your pet’s stay beyond the scheduled end date, please inform us as soon as possible. Additional days are subject to availability and will be charged at the daily rate.
          </li>
        </ul>
        <Title className={styles.title}>Health and Safety</Title>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}> Health Concerns:</span> If your pet shows signs of illness, injury, or aggression upon arrival or during their stay, we reserve the right to refuse service for the safety of our staff and other pets. You will be contacted immediately to make alternative arrangements.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Medical Emergencies:</span> In the case of a medical emergency, we will attempt to contact you or your emergency contact. If we are unable to reach you, we will seek veterinary care for your pet, and you will be responsible for any associated costs.
          </li>
        </ul>
        <Title className={styles.title}>Liability Waiver</Title>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Care and Safety:</span> We will take every precaution to ensure the safety and well-being of your pet while in our care. However, you acknowledge that certain risks are inherent in pet services, including but not limited to exposure to other animals, stress, and unforeseen injuries.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Waiver of Liability:</span> By making a reservation, you agree to release and hold harmless [Your Company Name], its employees, and affiliates from any liability, damages, or claims arising from your pet’s participation in our services.
          </li>
        </ul>
        <Title className={styles.title}>Service-Specific Terms</Title>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Pet Boarding:</span> Reservations must be made at least [insert number of days] in advance. Boarding includes daily care, feeding, and exercise. Special dietary needs or medical care must be arranged in advance.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Pickup and Drop Services:</span> Please provide accurate address details and preferred pickup/drop-off times at the time of booking. Additional charges may apply for late or long-distance pickups.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Pet Spa & Grooming:</span> Grooming reservations must include any specific instructions or preferences. If your pet’s behavior prevents safe grooming, the service may be suspended, and you will still be responsible for the full payment.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Pet Walking & Training:</span> Walking and training sessions are scheduled for [insert duration, e.g., 30 or 60 minutes] and may be adjusted depending on your pet’s needs. Please inform us of any behavioral issues or special instructions prior to the session.
          </li>
        </ul>

        <Title className={styles.title}>Contact Us</Title>
        <Text className={styles.generalText}>If you have any questions or concerns about this Privacy Policy, please contact us at:
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>PetsMitra
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>28/2A, Shakuntala Kanada Pa Haveli
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>Undri City,
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>Pune,
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>Maharashtra.
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>(+91) 8483-089-257
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>info@petsmitra.com
        </Text>
        <br />
        <br />
        <Text style={{ display: "block" }} className={styles.generalText}>Last updated: Oct 24, 2024
        </Text>
      </div>
    </>
  )
}

export default ReservationPolicy
