import React, { useState } from "react";
// import pune from "../Assets/Locations/Pune.png";
// import bangalore from "../Assets/Locations/Bangalore.png";
// import hyderabad from "../Assets/Locations/Hyderabad.png";
// import mumbai from "../Assets/Locations/Mumbai.png";
// import chennai from "../Assets/Locations/Chennai.png";
// import puneDog from "../Assets/Locations/PuneDog.png";
// import hyderabadDog from "../Assets/Locations/HyderabadDog.png";
// import chennaicat from "../Assets/Locations/chennaiCat.png";
// import mumbaicat from "../Assets/Locations/mumbaicat.png";
// import bangaloredog from "../Assets/Locations/Bangaloredog.png";
// import ourlocationsTitle from "../Assets/Locations/OurlocationsTitle.png";
// import LocationCard from "./Components/LocationCard";
import styles from "./Styles/location.module.css";
import { Col, Image, Row, Typography } from "antd";
import banglore from "../Assets/LocationsNew/Banglore.png";
import mumbai from "../Assets/LocationsNew/Mumbai.png";
import chennai from "../Assets/LocationsNew/chennai.png";
import pune from "../Assets/LocationsNew/Pune.png";
import hyderabad from "../Assets/LocationsNew/Hyderabad.png";

const { Text } = Typography;
const Locations = () => {
  const [hoveredCity, setHoveredCity] = useState(null);
  //   const locationCards = [
  //     {
  //       image: pune,
  //       city: "Pune",
  //       button: "Book appointment",
  //       pet: puneDog,
  //     },
  //     {
  //       image: bangalore,
  //       city: "Bengaluru",
  //       button: "Coming Soon",
  //       pet: bangaloredog,
  //     },
  //     {
  //       image: hyderabad,
  //       city: "Hyderabad",
  //       button: "Coming Soon",
  //       pet: hyderabadDog,
  //     },
  //     {
  //       image: mumbai,
  //       city: "Mumbai",
  //       button: "Coming Soon",
  //       pet: mumbaicat,
  //     },
  //     {
  //       image: chennai,
  //       city: "Chennai",
  //       button: "Coming Soon",
  //       pet: chennaicat,
  //     },
  //   ];
  return (
    // <div id='ourlocations' className={styles.entireLocations}>
    //     <img className={styles.locationTitle} src={ourlocationsTitle} alt='Our Locations' />
    //     <div style={{ margin: "20px" }}>
    //         <Row className={styles.locationCards}>
    //             {locationCards?.map(location => (
    //                 <Col key={location?.city}>
    //                     <LocationCard location={location} />
    //                 </Col>
    //             ))}
    //         </Row>
    //     </div>
    // </div>
    <div id="ourlocations" style={{ marginTop: "50px", marginBottom: "50px" }}>
      <Row
        gutter={[16, 16]}
        justify={"center"}
        style={{ alignItems: "center" }}
      >
        <Col xs={22} sm={20} md={8} lg={8} xl={8}>
          <Text className={styles.title}>
            Discover Our Presence Across India!
          </Text>
          <Text className={styles.paragraph}>
            Looking for unparalleled service right where you are? Look no
            further! We are proud to announce that we are available in the prime
            locations across India, ensuring our prime locations across India,
            ensuring our services are always within your reach.
          </Text>
        </Col>
        <Col xs={22} sm={20} md={14} lg={14} xl={14}>
          <Row gutter={[16, 16]}>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={8}
              onMouseEnter={() => setHoveredCity("chennai")}
              onMouseLeave={() => setHoveredCity(null)}
            >
              <div style={{ position: "relative" }}>
                <Image src={chennai} alt="chennai" preview={false} />
                {hoveredCity === "chennai" && (
                  <div className={styles.overlay}>
                    <Text className={styles.overlayText}>Chennai</Text>
                    <Text className={styles.overlayText}>Coming Soon...</Text>
                  </div>
                )}
              </div>
            </Col>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={8}
              onMouseEnter={() => setHoveredCity("mumbai")}
              onMouseLeave={() => setHoveredCity(null)}
            >
              <div style={{ position: "relative" }}>
                <Image src={mumbai} alt="mumbai" preview={false} />
                {hoveredCity === "mumbai" && (
                  <div className={styles.overlay}>
                    <Text className={styles.overlayText}>Mumbai</Text>
                    <Text className={styles.overlayText}>Coming Soon...</Text>
                  </div>
                )}
              </div>
            </Col>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={8}
              onMouseEnter={() => setHoveredCity("hyderabad")}
              onMouseLeave={() => setHoveredCity(null)}
            >
              <div style={{ position: "relative" }}>
                <Image src={hyderabad} alt="hyderabad" preview={false} />
                {hoveredCity === "hyderabad" && (
                  <div className={styles.overlay}>
                    <Text className={styles.overlayText}>Hyderabad</Text>
                    <Text className={styles.overlayText}>Coming Soon...</Text>
                  </div>
                )}
              </div>
            </Col>
            <Col
              xs={16}
              sm={16}
              md={16}
              lg={16}
              onMouseEnter={() => setHoveredCity("pune")}
              onMouseLeave={() => setHoveredCity(null)}
            >
              <div style={{ position: "relative" }}>
                <Image src={pune} alt="pune" preview={false} />
                {hoveredCity === "pune" && (
                  <div className={styles.overlay}>
                    <Text className={styles.overlayText}>Pune</Text>
                  </div>
                )}
              </div>
            </Col>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={8}
              onMouseEnter={() => setHoveredCity("bangalore")}
              onMouseLeave={() => setHoveredCity(null)}
            >
              <div style={{ position: "relative" }}>
                <Image src={banglore} alt="bangalore" preview={false} />
                {hoveredCity === "bangalore" && (
                  <div className={styles.overlay}>
                    <div className={styles.overlay}>
                      <Text className={styles.overlayText}>Bangalore</Text>
                      <Text className={styles.overlayText}>Coming Soon...</Text>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Locations;
