import React, { useEffect, useState } from "react";
import styles from "./Styles/help.module.css";
import { Col, Collapse, Input, Row, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { helpAction } from "./State/actions";
import Spinner from "../Spinner/Spinner";
import { CaretRightFilled, SearchOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
const { Text } = Typography;

const Help = () => {
  const dispatch = useDispatch();
  const { allQuestionsForscreen, totalQuestions } = useSelector(
    (str) => str.helpReducer
  );
  const { isLoading } = useSelector((str) => str.loadingReducer);
  const [page, setPage] = useState(1);

  // Debounce search function
  const debounceFun = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const searchClientFunc = debounceFun((value) => {
    dispatch(helpAction.getAllQuestionsEvent(1, value));
  }, 1000);

  useEffect(() => {
    dispatch(helpAction.getAllQuestionsEvent(1));
  }, []);

  const loadMore = () => {
    if (allQuestionsForscreen?.length < totalQuestions) {
      const nextPage = page + 1;
      dispatch(helpAction.getAllQuestionsEvent(nextPage));
      setPage(nextPage);
    }
  };

  return (
    <div>
      <div className={styles.mainheading}></div>
      <div className={styles.overlay}>
        <Text style={{ display: "block" }} className={styles.text}>
          Help Center
        </Text>
        <Input
          placeholder="Type Your Question Here"
          style={{ maxWidth: "500px", marginTop: "1.2em" }}
          size="large"
          prefix={<SearchOutlined />}
          allowClear
          autoFocus
          onChange={(e) => {
            const value = e.target.value;
            if (value.trim()) searchClientFunc(value);
          }}
          onClear={() => dispatch(helpAction.getAllQuestionsEvent(1))}
        />
      </div>
      {isLoading && page === 1 && <Spinner />}
      <div id="scrollableDiv">
        <InfiniteScroll
          dataLength={allQuestionsForscreen && allQuestionsForscreen?.length}
          scrollableTarget="scrollableDiv"
          hasMore={allQuestionsForscreen?.length < totalQuestions}
          next={loadMore}
          scrollThreshold={0.5}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          loader={
            isLoading && (
              <div style={{ textAlign: "center", padding: "50px" }}>
                <Spin />
              </div>
            )
          }
        >
          <Row justify={"center"} style={{ margin: "1.2em" }}>
            <Col xs={22} sm={20} md={18} lg={16}>
              <Collapse
                size="large"
                accordion
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <CaretRightFilled rotate={isActive ? 90 : 0} />
                )}
                items={
                  allQuestionsForscreen &&
                  allQuestionsForscreen?.map((question) => ({
                    key: question?.questionUniqueId,
                    label: (
                      <Text style={{ fontWeight: 500, fontSize: "1em" }}>
                        {question?.title}
                      </Text>
                    ),
                    children: (
                      <p
                        className={styles.descImg}
                        dangerouslySetInnerHTML={{
                          __html: question.description?.["en"],
                        }}
                      />
                    ),
                  }))
                }
              />
            </Col>
          </Row>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Help;
