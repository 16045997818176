import React, { useEffect, useState } from "react";
import { Button, Dropdown, Image, Layout, Menu, Select, Space, Typography } from "antd";
import { DownOutlined, GlobalOutlined, MenuOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import Logo from "../Assets/Navbar/Logo.png";
// import LoginSignup from "../Assets/Navbar/login_signup.png";
import Logo from "../Assets/Frame 32 (1).png";
import LoginSignup from "../Assets/Group 3.png";
import styles from "./Styles/header.module.css";
import logoStyles from "./Styles/logo.module.css";
import { useMediaQuery } from "react-responsive";
import { language } from "../Common/Components/multilanguages";
import { navbarActions } from "./state/actions";
import LanguageItems from "./Components/LanguageItems";
// import { useTranslation } from "react-i18next";
const { Header } = Layout;
const { Text } = Typography;

function Navbar() {
  const dispatch = useDispatch();
  const { defaultLanguage } = useSelector((state) => state.navbarReducer);
  // console.log(defaultLanguage);
  const isMobile = useMediaQuery({ maxWidth: 890 });
  const navigate = useNavigate();
  const location = useLocation();
  // const [activeKey, setActiveKey] = useState("home");
  // const handleClick = (e) => {
  //   setActiveKey(e.key);
  // };
  // const { t } = useTranslation();

  const handleNavigation = (target) => {
    if (location.pathname !== "/") {
      // If not on the homepage, navigate to the home route first
      navigate("/");
    }
    // After navigating to home, scroll to the target section
    setTimeout(() => {
      const element = document.getElementById(target);
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }, 100); // Delay to ensure the home route has loaded
  };

  // useEffect(() => {
  //   const pathname = location.pathname.split("/")[1];
  //   if (pathname === "") setActiveKey("home");
  //   else setActiveKey(pathname);
  // }, [location.pathname]);

  const items = [
    {
      label: (
        <div onClick={() => handleNavigation("onBoard")}>
          {/* {t('onboard')} */}
          Onboard
        </div>
      ),
      key: "onboard",
    },
    {
      type: "divider",
      dashed: true,
    },
    {
      label: (
        <div onClick={() => handleNavigation("services")}>
          Our services
        </div>
      ),
      key: "services",
    },
    {
      type: "divider",
      dashed: true,
    },
    {
      label: (
        <Link to={'/news'}>
          News & Blogs
        </Link>
      ),
      key: "news",
    },
    {
      type: "divider",
      dashed: true,
    },
    {
      label: (
        <Link to={'/contactUs'}>
          Get In Touch
        </Link>
      ),
      key: "getintouch",
    },
    {
      type: "divider",
      dashed: true,
    },
    {
      label: (
        <Link to={'/faqs'}>
          FAQs
        </Link>
      ),
      key: "faqs",
    },
    isMobile
      ? {
        type: "divider",
        dashed: true,
      }
      : null,
    isMobile
      ? {
        label: (
          <Link to={process.env.REACT_APP_WEB_APP_URL} target="_blank">
            Log in/Sign in
          </Link>
        ),
        key: "loginsignin",
      }
      : null,
  ];
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let handleChange = (languageArgument) => {
    console.log("languageArgument", languageArgument);
    dispatch(navbarActions.changeLanguage(languageArgument));
  };

  // const languageItems = LanguageItems();

  return (
    <Header
      className={`${styles.headerStyle} ${scrolled ? styles.scrolled : ""}`}
    >
      <div className={logoStyles.LogoAlignment}>
        <Link to="/">
          <Image
            preview={false}
            className={styles.logo}
            src={Logo}
            alt="Logo"
          />
        </Link>
      </div>

      <Menu
        theme="light"
        mode="horizontal"
        // onClick={handleClick}
        // selectedKeys={[activeKey]}
        style={{ backgroundColor: "transparent" }}
        items={items}
        className={styles.menu}
        overflowedIndicator={<MenuOutlined />}
      />

      <>
        {/* language selection */}

        {/* <Dropdown menu={{ items: languageItems }}>
          <div style={{ marginRight: '1.2em' }}>
            <GlobalOutlined style={{ color: 'white', fontSize: "1.5rem" }} />
            <DownOutlined style={{ color: 'white', fontSize: "1rem" }} />
          </div>
        </Dropdown> */}

        {/* <Select
          defaultValue="English"
          style={{
            width: 120,
          }}
          onChange={handleChange}
          options={language}
        /> */}
      </>

      <div
        className={logoStyles.LogoAlignmentRight}
        style={{ display: isMobile ? "none" : "initial" }}
      >
        <Link to={process.env.REACT_APP_WEB_APP_URL}>
          <Image
            src={LoginSignup}
            preview={false}
            className={styles.logo}
            alt="Login_signup"
          />
        </Link>
        {/* <Button style={{height:40,width:150,fontSize:"1.2em"}}>Get Started</Button> */}
      </div>
    </Header>
  );
}
export { Navbar };
