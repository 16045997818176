import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from "react-router-dom";
import { ScrollToTop } from "./Common/Components/ScrollToTop";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { store } from "./store";
import Spinner from "./Spinner/Spinner";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    theme={{
      token: {
        fontFamily: `Poppins,sans-serif`
      },
      components: {
        Menu: {
          horizontalItemSelectedColor: "none",
          itemSelectedColor: "none",
          itemHoverColor: "none",
          activeBarWidth: 10,
          colorBgElevated: "#5E1909",
          itemColor: "white",
          itemSelectedBg: "#581203",
          colorSplit: "white",
          fontFamily: `Poppins,sans-serif`,
        },
      },
    }}
  >
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <ScrollToTop />
        <Suspense fallback={<Spinner />}>
          <App />
        </Suspense>
      </BrowserRouter>
      </I18nextProvider>
    </Provider>
  </ConfigProvider>
);
