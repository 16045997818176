import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newsAction } from "./State/actions";
import { Card, Image, Row, Col, Typography, Spin } from "antd";
import Spinner from "../Spinner/Spinner";
import styles from '../Common/Styles/common.module.css';
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
const { Meta } = Card;
const { Paragraph } = Typography;

const News = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const { allBlogs, totalBlogs } = useSelector((str) => str.newsReducer);
    const { isLoading } = useSelector((str) => str.loadingReducer);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(newsAction.getAllBlogsEvent(1));
    }, []);

    const loadMore = () => {
        if (allBlogs?.length < totalBlogs) {
            const nextPage = page + 1;
            dispatch(newsAction.getAllBlogsEvent(nextPage));
            setPage(nextPage);
        }
    };

    if (isLoading && page === 1) return <Spinner />;
    // console.log(allBlogs?.length, 'allBlogs?.length')

    return (
        <div id="scrollableDiv">
            <div style={{ background: "black" }} className={styles.navbarmenu}></div>
            {/* <div id="scrollableDiv"> */}
            <InfiniteScroll
                dataLength={allBlogs && allBlogs?.length}
                scrollableTarget="scrollableDiv"
                hasMore={allBlogs?.length < totalBlogs}
                next={loadMore}
                scrollThreshold={0.5}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
                loader={
                    isLoading && (
                        <div style={{ textAlign: "center", padding: "50px" }}>
                            <Spin />
                        </div>
                    )
                }
            >
                <Row gutter={16} justify={'center'} style={{ margin: "1.2em" }}>
                    {allBlogs &&
                        allBlogs?.map((blog) => (
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Card
                                    onClick={() => navigate(`/${blog?.redirectURL}`)}
                                    hoverable
                                    style={{ borderRadius: 0 }}
                                    cover={
                                        <Image
                                            preview={false}
                                            style={{ borderRadius: 0 }}
                                            src={blog?.thumbnail}
                                        />
                                    }
                                >
                                    <Paragraph
                                        style={{ margin: 0, fontWeight: 500 }}
                                        ellipsis={{ rows: 2, expandable: true, symbol: "Know More" }}
                                    >
                                        {blog?.title}
                                    </Paragraph>
                                </Card>
                            </Col>
                        ))}
                </Row>
            </InfiniteScroll>
            {/* </div> */}
        </div>
    );
};

export { News };
