import React from "react";
import { Button, Card, Image, Typography } from "antd";
import styles from "../Styles/Knowthem.module.css";
import { Link, useNavigate } from "react-router-dom";
const { Paragraph } = Typography;

const PetCard = ({ pet, fixWidth, loading }) => {
  const navigate = useNavigate();

  return (
    <>
      <Card
        className={styles.cardStyle}
        style={{ width: fixWidth ? "350px" : "" }}
        cover={
          // <Link to={`/pet/${pet?.redirectURL}`}  style={{width:"100%"}}>
          <Image
            src={pet?.thumbnail}
            preview={false}
            className={styles.imageStyle}
            onClick={() => navigate(`/pet/${pet?.redirectURL}`)}
          />
          // </Link>
        }
        loading={loading}
        styles={{
          body: {
            backgroundColor: "#315D80",
            padding: 0,
            // borderRadius: 0,
            // fontFamily: "Poppins,sans-serif",
            color: "white",
          },
          actions: {
            backgroundColor: "#315D80",
          },
        }}
      // actions={[<Button onClick={() => navigate(`/pet/${pet?.redirectURL}`)}>Know More</Button>]}
      >
        <div className={styles.bodyStyle}>
          <Link to={`/pet/${pet?.redirectURL}`} style={{ textDecoration:"underline" }}>
            <div className={styles.petName}>
              {pet?.type}-{pet?.breed}
            </div>
          </Link>
          <p
            dangerouslySetInnerHTML={{ __html: pet?.description }}
            className={styles.descImg}
          />
          {/* <div className={styles.text}>{pet?.type}</div>
          <div className={styles.text}>
            Characteristics-{pet?.characteristics}
          </div>
          <div className={styles.text}>Age- {pet?.age}</div>
          <div className={styles.text}>Group-{pet?.group}</div> */}
        </div>
      </Card>
    </>
  );
};

export default PetCard;
