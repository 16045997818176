import React from "react";
import disclaimerImage from "../Assets/static/disclaimer.png";
import { Image, Typography } from "antd";
import styles from "./Styles/static.module.css";
const { Text, Title } = Typography;

const RefundCancellationPolicy = () => {
  return (
    <>
      <div style={{ position: "relative" }}>
        <Image
          src={disclaimerImage}
          alt="Privacy_policy"
          className={styles.image}
          preview={false}
        />
        <div className={styles.overlay}>
          <Text style={{ display: "block" }} className={styles.text}>
            Our cancellation policy is designed to offer flexibility and high-quality service.
          </Text>
        </div>
      </div>
      <div style={{ margin: "3rem" }}>
        <Text className={styles.generalText}>
          At [Your Company Name], we aim to provide the best care for your pets while maintaining flexibility and understanding your needs. This Refund & Cancellation Policy outlines the terms for cancellations, refunds, and rescheduling related to our pet services, including pet boarding, pickup and drop, spa, walking, and training. Please review the policy carefully before booking.
        </Text><br />
        <Title className={styles.title}>Cancellation Policy</Title>
        <Text className={styles.generalText}>We understand that plans can change unexpectedly. Our cancellation policy is designed to offer flexibility while ensuring that we can maintain high-quality service for all our clients.
        </Text>
        <Title className={styles.heading}>Pet Boarding & Overnight Stays
        </Title>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Cancellations made 48 hours or more before the boarding date:</span> Full refund.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Cancellations made 24-48 hours before the boarding date:</span> 50% refund.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Cancellations made less than 24 hours before the boarding date:</span> No refund.
          </li>
        </ul>
        <Title className={styles.heading}>Pet Pickup & Drop Services
        </Title>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Cancellations made 24 hours or more before the scheduled service</span> Full refund.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Cancellations made less than 24 hours before the scheduled service: </span> No refund.
          </li>
        </ul>
        <Title className={styles.heading}>Pet Spa, Pet Walking & Pet Trainer Services
        </Title>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Cancellations made 24 hours or more before the appointment:</span> Full refund or option to reschedule.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Cancellations made less than 24 hours before the appointment: </span> No refund. However, we may allow rescheduling based on availability and at our discretion.
          </li>
        </ul>

        <Title className={styles.title}>Rescheduling Policy</Title>
        <Text className={styles.generalText}>If you wish to reschedule any service, please contact us as early as possible:
        </Text>
        <Title className={styles.heading}>Pet Boarding & Overnight Stays
        </Title>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Rescheduling pet boarding or overnight stays:</span> Must be done at least 48 hours before the scheduled date to avoid additional charges. Rescheduling within 24-48 hours may incur a 20% rescheduling fee, and within 24 hours, no rescheduling is allowed.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Rescheduling pet spa, walking, or training services:</span> Must be done at least 24 hours before the appointment to avoid a rescheduling fee. Rescheduling requests within 24 hours may incur a 10% fee and are subject to availability.
          </li>
        </ul>
        <Title className={styles.title}>Refund Policy</Title>
        <Text className={styles.generalText}>We aim to process refunds promptly after receiving a valid cancellation request. Refunds will be issued to the original payment method used at the time of booking, and it may take 5-7 business days to reflect in your account depending on your financial institution.
        </Text>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Full Refund:</span> Issued for cancellations made according to the timeframes specified above.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Partial Refund:</span> Issued for late cancellations as per the conditions outlined in this policy.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>No Refund:</span> If services are canceled outside the allowed cancellation window, or for missed appointments and no-shows, no refund will be provided.
          </li>
        </ul>
        <Title className={styles.title}>Non-Refundable Services</Title>
        <Text className={styles.generalText}>The following situations are non-refundable:
        </Text>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Emergency cancellations:</span> Cancellations made on the day of service due to emergencies (e.g., health issues, emergencies at home) may be reviewed on a case-by-case basis, but refunds are not guaranteed.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Service Add-ons:</span> Any add-on services requested during the boarding stay or spa appointments (e.g., grooming, extra walks, special diet) will not be eligible for refunds.
          </li>
        </ul>
        <Title className={styles.title}>How to Cancel or Reschedule a Service</Title>
        <Text className={styles.generalText}>To cancel or reschedule a service, please contact us through one of the following methods:
        </Text>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>By Phone:</span> Call us at [Your Phone Number].
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>By Email:</span> Email your cancellation or rescheduling request to [Your Email Address].
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Through Our Website:</span>  Log in to your account on [Your Website] and manage your bookings through our online system.
          </li>
        </ul>
        <Title className={styles.title}>Late Pickups for Boarding Services</Title>
        <Text className={styles.generalText}>If you are unable to pick up your pet at the agreed time after boarding:
        </Text>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Up to 2 hours late:</span> No extra charges.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>More than 2 hours late:</span> An additional day’s boarding fee may be charged, depending on availability.
          </li>
        </ul>
        <Title className={styles.title}>Changes to This Policy</Title>
        <Text className={styles.generalText}>We reserve the right to update or modify this Refund & Cancellation Policy at any time. Any changes will be posted on this page with the "Last Updated" date. It is your responsibility to review the policy periodically for any updates.
        </Text>

        <Title className={styles.title}>Contact Us</Title>
        <Text className={styles.generalText}>If you have any questions or concerns regarding our Refund & Cancellation Policy, please contact us at:
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>PetsMitra
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>28/2A, Shakuntala Kanada Pa Haveli
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>Undri City,
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>Pune,
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>Maharashtra.
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>(+91) 8483-089-257
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>info@petsmitra.com
        </Text>
        <br />
        <br />
        <Text style={{ display: "block" }} className={styles.generalText}>Last updated: Oct 24, 2024
        </Text>
        
      </div>
    </>
  );
};

export default RefundCancellationPolicy;
