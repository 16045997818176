import axios from "axios";

const apiURL = process.env.REACT_APP_DEPLOYED_URL;

// Post data
const addDataRequest = (data, role) => {
  // let token = localStorage.getItem("auth-token");
  // console.log(data);

  const config = {
    headers: {
      accept: "application/json",
      // "Content-Type": "multipart/form-data",
      "Content-Type": "application/json",
    },
  };

  let url;

  if (role) {
    url = `${apiURL}/getInTouch/${role}`;
  } else {
    url = `${apiURL}/getInTouch/user`;
  }

  return axios.post(url, data, config);
};

export const commonApi = {
  addDataRequest,
};
