import React from "react";
import styles from "../styles/contactUs.module.css";
import { Button, Col, Flex, Image, Row, Typography } from "antd";
import contact from "../../Assets/Contact/contact.png";
import {
    ArrowDownOutlined,
    EnvironmentFilled,
    FacebookFilled,
    InstagramFilled,
    LinkedinFilled,
    MailFilled,
    MailOutlined,
    PhoneOutlined,
    XFilled,
    XOutlined,
    YoutubeFilled,
} from "@ant-design/icons";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { ContactForm } from "../ContactForm";
import GetInTouch from "../../Home/GetInTouch";
import { Link } from "react-router-dom";
const { Text, Title } = Typography;

const ContactUs = () => {
    const handleNavigation = () => {
        // After navigating to home, scroll to the target section
        setTimeout(() => {
            const element = document.getElementById('contactUs');
            if (element) element.scrollIntoView({ behavior: "smooth" });
        }, 100); // Delay to ensure the home route has loaded
    };

    return (
        <div>
            <div className={styles.contactHeading}>
                <div className={styles.overlay}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            // alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                        }}
                    >
                        <Title
                            style={{ color: "white", display: "block", textAlign: "center" }}
                        >
                            Contact Us
                        </Title>
                        <Text className={styles.text} style={{ textAlign: "center" }}>
                            Feel free to use the contact form below to reach out to us,
                            Our Team will contact you soon.
                        </Text>
                        <Row gutter={16} justify={"center"} style={{ width: "100%", marginTop: "2em" }}>
                            <Col xs={24} sm={24} md={12} lg={10} xl={8} className={styles.firstCol}>
                                <Text className={styles.reachText}>Reach us through</Text>
                                <Text className={styles.normaltext}>
                                    <MailFilled />
                                    &nbsp;info@petsmitra.com
                                </Text>
                                <Text className={styles.normaltext}>
                                    <FaPhoneAlt />
                                    &nbsp;(+91) 8483-089-257
                                </Text>
                                <Text className={styles.normaltext}>
                                    <EnvironmentFilled />
                                    &nbsp;28/2A, Shakuntala Kanada Pa Haveli, Undari City, Pune,
                                    Maharashtra.
                                </Text>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={10} xl={8}>
                                <Text className={styles.reachText}>Social Networks</Text>
                                <Flex vertical={false} style={{ padding: `0px 3rem 1rem 3rem` }}>
                                    <Link to={"https://www.facebook.com/profile.php?id=61567145026713"}>
                                        <FacebookFilled className={styles.icon} />
                                    </Link>
                                    <Link to={"https://x.com/myPetsMitra"}>
                                        <XOutlined className={styles.icon} />
                                    </Link>
                                    <Link to={"https://www.youtube.com/@myPetsMitra"}>
                                        <YoutubeFilled className={styles.icon} />
                                    </Link>
                                    <Link to={'https://www.instagram.com/mypetsmitra'}>
                                        <InstagramFilled className={styles.icon} />
                                    </Link>
                                    <Link to={'https://www.linkedin.com/company/mypetsmitra'}>
                                        <LinkedinFilled className={styles.icon} />
                                    </Link>
                                </Flex>
                                <div className={styles.btn}>
                                    <Button size="large" className={styles.getinTouch} onClick={handleNavigation}>Get In Touch</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div id="contactUs">
                <GetInTouch />
            </div>
        </div>
    );
};

export default ContactUs;
