import React from 'react';
import termsImage from '../Assets/static/terms.png';
import { Image, Typography } from 'antd';
import styles from './Styles/static.module.css';
const { Text, Title } = Typography;

const TermsAndConditions = () => {
  return (
    <>
      <div style={{ position: 'relative' }}>
        <Image
          src={termsImage}
          alt="Privacy_policy"
          className={styles.image}
          preview={false}
        />
        <div className={styles.overlay}>
          <Text style={{ display: "block" }} className={styles.termsofuseText}>
            Terms of use
          </Text>
          <Text style={{ display: "block" }} className={styles.text}>
            Accessing our site implies agreement to our terms of use
          </Text>
        </div>
      </div>
      <div style={{ margin: "3rem" }}>
        <Text className={styles.generalText}>
          Welcome to Petsmitra (“we,” “us,” or “our”). By accessing or using our website and services, you agree to comply with and be bound by the following Terms of Use. Please read these terms carefully before using our website or services. If you do not agree with any part of these terms, you must discontinue use of our services and website immediately.
        </Text><br />
        <Title className={styles.title}>Services</Title>
        <Text className={styles.generalText}>Petsmitra provides a range of services for pet owners, including but not limited to pet boarding, pet pickup and drop, pet spa, pet walking, and pet training services (collectively, "Services"). By booking any of these services through our website or other means, you agree to the specific terms and conditions of each service, which may be outlined separately during the booking process.
        </Text>
        <Title className={styles.title}>Eligibility</Title>
        <Text className={styles.generalText}>By using our website, you confirm that:
        </Text>
        <ul>
          <li className={styles.generalText}>You are at least 18 years of age or older.
          </li>
          <li className={styles.generalText}>You are legally capable of entering into binding contracts.
          </li>
          <li className={styles.generalText}>You are the lawful owner or authorized guardian of any pets for which services are booked.
          </li>
        </ul>
        <Title className={styles.title}>User Responsibilities</Title>
        <Text className={styles.generalText}>You agree to:
        </Text>
        <ul>
          <li className={styles.generalText}>Provide accurate and up-to-date information, including pet details, contact information, and payment details.
          </li>
          <li className={styles.generalText}>Ensure that your pet is properly vaccinated, in good health, and fit for services such as walking, training, or spa treatments.
          </li>
          <li className={styles.generalText}>Notify us of any behavioral issues, health conditions, or special care requirements for your pet.
          </li>
          <li className={styles.generalText}>Comply with all instructions provided by our staff related to the safety, care, and well-being of your pet.
          </li>
        </ul>
        <Title className={styles.title}>Pet Health and Safety</Title>
        <Text className={styles.generalText}>While we take all necessary precautions to ensure the safety and well-being of your pet, you acknowledge that:
        </Text>
        <ul>
          <li className={styles.generalText}>We are not liable for any injury, illness, or death that may occur during or as a result of the services provided, except where such injury, illness, or death is due to our gross negligence or willful misconduct.
          </li>
          <li className={styles.generalText}>You are responsible for providing accurate medical information and vaccination records prior to the service.
          </li>
        </ul>
        <Title className={styles.title}> Booking and Payment</Title>
        <ul>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Booking:</span> All bookings must be made in advance through our website or customer service. Services are subject to availability.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Payment:</span> Payment for services must be made at the time of booking or as otherwise specified. We accept major credit/debit cards and other approved payment methods. All payments are non-refundable unless otherwise stated in our cancellation policy.
          </li>
          <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Cancellations:</span> If you need to cancel or reschedule a booking, please review our [Cancellation Policy] for details regarding refunds or rescheduling.
          </li>
        </ul>
        <Title className={styles.title}>Limitation of Liability</Title>
        <Text className={styles.generalText}>To the fullest extent permitted by law, Petsmitra and its employees, agents, or contractors will not be liable for:
        </Text>
        <li className={styles.generalText}>Any direct, indirect, incidental, or consequential damages resulting from your use of or inability to use our website or services.
        </li>
        <li className={styles.generalText}>Any damages related to your pet's injury, illness, or death, except as caused by our gross negligence or willful misconduct.
        </li>
        <Title className={styles.title}>Intellectual Property</Title>
        <Text className={styles.generalText}>All content on our website, including but not limited to text, images, graphics, logos, and software, is the property of Petsmitra or its content suppliers and is protected by intellectual property laws. You may not use, reproduce, or distribute any content from our website without our explicit permission.
        </Text>
        <Title className={styles.title}>Prohibited Activities</Title>
        <Text className={styles.generalText}>When using our website or services, you agree not to:
        </Text>
        <li className={styles.generalText}>Misrepresent your identity, ownership, or guardianship of any pet.
        </li>
        <li className={styles.generalText}>Use our services for any illegal or unauthorized purpose.
        </li>
        <li className={styles.generalText}>Disrupt the functionality of our website, including the introduction of harmful code or unauthorized access to the site or our systems.
        </li>
        <Title className={styles.title}>Privacy Policy</Title>
        <Text className={styles.generalText}>Your use of our website and services is also governed by our [Privacy Policy], which explains how we collect, use, and protect your personal and pet-related information. By using our services, you agree to the terms of our Privacy Policy.
        </Text>
        <Title className={styles.title}>Changes to Terms</Title>
        <Text className={styles.generalText}>We may update or modify these Terms of Use from time to time. Any changes will be posted on our website with the “Last Updated” date. Continued use of the website and services after such changes constitutes your acceptance of the revised terms.
        </Text>
        <Title className={styles.title}>Governing Law</Title>
        <Text className={styles.generalText}>These Terms of Use are governed by the laws of [Your Country/State]. Any disputes arising from or related to these terms or the use of our services will be resolved exclusively in the courts of [Your Country/State].
        </Text>
        <Title className={styles.title}>Contact Us</Title>
        <Text className={styles.generalText}>If you have any questions or concerns about these Terms Of Use, please contact us at:
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>Petsmitra
        </Text>
        <Title className={styles.title}>Contact Us</Title>
        <Text className={styles.generalText}>If you have any questions or concerns about this Privacy Policy, please contact us at:
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>PetsMitra
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>28/2A, Shakuntala Kanada Pa Haveli,
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>Undri City,
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>Pune,
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>Maharashtra.
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>(+91) 8483-089-257
        </Text>
        <Text style={{ display: "block" }} className={styles.generalText}>info@petsmitra.com
        </Text>
        <br />
        <br />
        <Text style={{ display: "block" }} className={styles.generalText}>Last updated: Oct 24, 2024
        </Text>
      </div>
    </>
  )
}

export default TermsAndConditions
