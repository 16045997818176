import React from "react";
import { Layout, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import Slider from "react-slick";

import petBoarding from "../../Assets/serviceDetailsImages/pb.png";
import petPickupDrop from "../../Assets/serviceDetailsImages/ppd.png";
import petDaycare from "../../Assets/serviceDetailsImages/pdc.png";
import petTraining from "../../Assets/serviceDetailsImages/pt.png";
import petSpa from "../../Assets/serviceDetailsImages/spa.png";
import petWalking from "../../Assets/serviceDetailsImages/pw.png";
import petSitting from "../../Assets/serviceDetailsImages/ps.png";
import petAdoption from "../../Assets/serviceDetailsImages/pa.png";
import petMaternity from "../../Assets/serviceDetailsImages/pm.png";
import petCremation from "../../Assets/serviceDetailsImages/pc.png";
import petFashion from "../../Assets/serviceDetailsImages/pf.png";
import SlideServiceDetails from "./SlideServiceDetails";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../Styles/serviceDetailsCarousel.module.css";

const { Content } = Layout;

const petServiceDetails = [
  {
    heading: "Pet boarding",
    title: "Welcome to our premium pet boarding service",
    description:
      "Where your furry friends are treated like family. Our state-of-the-art facilities ensure a comfortable stay, complete with personalized care, spacious accommodations, & regular playtime. Trust us to provide a home away from home for your pets, where their well-being and happiness are our top priorities.",
    image: petBoarding,
    button: "Book appointment",
    disabled: false,
  },
  {
    heading: "Pet pick up & drop",
    title: "Welcome to our premium pet pick up & drop service",
    description:
      "Experience hassle-free pet transportation with our reliable pick-up and drop service. Our trained professionals ensure your pet's comfort and safety throughout the journey. From door to door, we provide a seamless and stress-free experience for both you & your beloved furry friend. Trust us for convenient and caring pet transport services.",
    image: petPickupDrop,
    button: "Book appointment",
    disabled: false,
  },
  {
    heading: "Pet day care",
    title: "At Our Pet Day Care ",
    description:
      "We provide a loving and secure environment for your furry friends. Our experienced staff offers personalized attention, engaging activities, and a safe space for play. Whether it's daily care or overnight stays, trust us to pamper and care for your pets like family. Happy, healthy pets, guaranteed!",
    image: petDaycare,
    button: "Book appointment",
    disabled: true,
  },
  {
    heading: "Pet training",
    title:
      "Transform your furry friend into the perfect companion with our expert pet training service.",
    description:
      "Our certified trainers use positive reinforcement methods to address behavior issues and enhance obedience. From basic commands to advanced skills, we tailor our approach to suit your pet's needs. Experience the joy of a well-behaved and happy pet with us!",
    image: petTraining,
    button: "Book appointment",
    disabled: true,
  },
  {
    heading: "SPA & grooming",
    title:
      "Indulge your furry friends in the ultimate pampering experience at our Pet SPA & Grooming Service.",
    description:
      "Our skilled professionals provide top-notch grooming services, ensuring your pets look and feel their best. Treat them to a day of relaxation, luxury baths, and expert grooming for a radiant and happy pet.",
    image: petSpa,
    button: "Book appointment",
    disabled: true,
  },
  {
    heading: "Pet walking",
    title: "Experience unparalleled care with Our Pet Walking Service!",
    description:
      "Our team of dedicated professionals ensures your furry friend receives the love and exercise they deserve. Tailored walks, personalized attention, and a commitment to your pet's well-being. Trust us to keep their tails wagging with joy. Book a walk today for happiness unleashed!",
    image: petWalking,
    button: "Book appointment",
    disabled: true,
  },
  {
    heading: "Pet sitting",
    title: "Discover peace of mind with our premier pet sitting service",
    description:
      "Our experienced and caring team ensures your furry friends receive top-notch care in the comfort of their own home. From daily walks to personalized attention, we go the extra mile to keep tails wagging and whiskers purring. Trust us with your pets!",
    image: petSitting,
    button: "Book appointment",
    disabled: true,
  },
  {
    heading: "Pet adoption",
    title: "Welcome to our pet adoption service!",
    description:
      "Discover furry companions ready to fill your home with love. Our diverse selection includes dogs & cats, each seeking a forever home. Experience the joy of adoption with us—where tails wag and purrs abound. Find your perfect match and make a difference in a pet's life today!",
    image: petAdoption,
    button: "Book appointment",
    disabled: true,
  },
  {
    heading: "Pet maternity",
    title: "Experience peace of mind with our pet maternity service",
    description:
      "Our expert team provides attentive care for expecting pets, ensuring their comfort and health throughout pregnancy. From regular check-ups to personalized nutrition plans, we’re dedicated to supporting both you and your furry companion during this special journey. Trust us for compassionate maternity care.",
    image: petMaternity,
    button: "Book appointment",
    disabled: true,
  },
  {
    heading: "Pet cremation",
    title: "Our compassionate pet cremation service",
    description:
      "Provides a dignified farewell to beloved companions. With utmost care and respect, we offer individual cremations, ensuring each pet receives personalized attention. We understand the profound bond between pets and their owners, offering solace through a thoughtful and respectful farewell for cherished furry family members.",
    image: petCremation,
    button: "Book appointment",
    disabled: true,
  },
  {
    heading: "Pet fashion show",
    title: "Step into the world of glamour with our Pet Fashion Show service!",
    description:
      "Treat your furry friends to a runway experience like no other. From stylish costumes to adorable accessories, we ensure your pets steal the spotlight. Let them strut their stuff in a showcase of cuteness and charm, leaving tails wagging!",
    image: petFashion,
    button: "Book appointment",
    disabled: true,
  },
];

const ServiceDetailsCarousel = () => {
  const CustomPrevArrow = ({
    currentSlide,
    slideCount,
    children,
    ...props
  }) => (
    <div
      {...props}
      style={{
        position: "absolute",
        left: "8px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
        background: "#3A5FA0",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      &lt;
    </div>
  );

  const CustomNextArrow = ({
    currentSlide,
    slideCount,
    children,
    ...props
  }) => (
    <div
      {...props}
      style={{
        position: "absolute",
        right: "8px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
        background: "#3A5FA0",
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      &gt;
    </div>
  );

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    autoplay: false,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    focusOnChange: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    useCSS: true,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: true,
          centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Content className={styles.reservationContent}>
      <Row>
        {/* Big Screens */}
        <Slider
          {...settings}
          // style={{
          //   display: isMobile ? "none" : "initial",
          // }}

          className={styles.slider}
        >
          {petServiceDetails?.map((service) => (
            <SlideServiceDetails
              key={uuidv4()}
              heading={service?.heading}
              title={service?.title}
              description={service?.description}
              image={service?.image}
              button={service?.button}
              disabled={service?.disabled}
            />
          ))}
        </Slider>
      </Row>
    </Content>
  );
};

export default ServiceDetailsCarousel;
