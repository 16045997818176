import axios from "axios";

const apiURL = process.env.REACT_APP_DEPLOYED_URL;

// get question based on screen and platform
const getAllquestions = (page, search) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    let url = `${apiURL}/getAllHelp?page=${page}`;
    if (search) url += `&search=${search}`
    // let url = `${apiURL}/getAllHelp`
    return axios.get(url, config);
}

export const helpApi = {
    getAllquestions
};