import React from "react";
import { Col, Image, Layout, Row, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
import icon1 from "../../Assets/PrivacyReservation/icon1.png";
import icon2 from "../../Assets/PrivacyReservation/icon2.png";
import icon3 from "../../Assets/PrivacyReservation/icon3.png";
import styles from "../Styles/privacyReservation.module.css";
import { Link } from "react-router-dom";

const { Content } = Layout;
const { Title, Text } = Typography;

const content = [
  {
    image: icon1,
    title: "Privacy policy",
    navigateTo:"privacyPolicy",
    description:
      `Petsmitra ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our pet services, including pet boarding, pet pickup and drop, pet spa, pet walking, and pet training services. By using our services, you agree to the practices outlined in this policy.`
  },
  {
    image: icon2,
    title: "Reservation policy",
    navigateTo:"reservationPolicy",
    description:
      `At Pets Mitra, we aim to provide the best possible care and services for your pets. To ensure a smooth experience, please review our Reservation Policy before booking any of our services, including pet boarding, pet pickup and drop, pet spa, pet walking, and pet training.`
  },
  {
    image: icon3,
    title: "Disclaimer",
    navigateTo:"disclaimer",
    description:
      `By accessing or using the services provided by [Your Company Name] ("we," "us," or "our"), including pet boarding, pet pickup and drop, pet spa, pet walking, and pet training services, you acknowledge and agree to the terms outlined in this Disclaimer. If you do not agree to these terms, please refrain from using our services.`
  },
];

const PrivacyReservation = () => {
  return (
    <Content className={styles.privacyContent} id="Reservation">
      <section className={styles.mainPart}>
        <Row className={styles.mainTextContainer}>
          <Col span={24}>
            <Title className={styles.title} style={{ display: "block" }}>
              We prioritize pet safety, health, and happiness
            </Title>
          </Col>

          <Col span={24}>
            <Text
              style={{ display: "block", textAlign: "center" }}
              className={styles.text}
            >
              Our services adhere to strict standards of care, professionalism,
              and accountability.
            </Text>
          </Col>

          <Col span={24}>
            <Text
              style={{ display: "block", textAlign: "center" }}
              className={styles.text}
            >
              Your pet's well-being is our top priority.
            </Text>
          </Col>
        </Row>

        <Row
          gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
          style={{ padding: "25px" }}
        >
          {content?.map((data) => (
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} key={uuidv4()}>
              <div className={styles.card}>
                <div className={styles.iconSet}>
                  <Image
                    src={data?.image}
                    preview={false}
                    alt={data?.title}
                    className={styles.icon}
                  />
                  <Text className={styles.iconText}>{data?.title}</Text>
                </div>

                <div className={styles.descriptionContainer}>
                  <Text
                    style={{ display: "block" }}
                    className={styles.description}
                  >
                    {data?.description} <Link to={`${data?.navigateTo}`}>Know more...</Link>
                  </Text>
                </div>
              </div>
            </Col>
          ))}
          <Col lg={8}></Col>
          <Col lg={8}></Col>
        </Row>
      </section>
    </Content>
  );
};

export default PrivacyReservation;
