import React, { useState } from "react";
import { Row, Col, Image, Modal, Typography } from "antd";
import styles from "../Styles/ourProvidings.module.css";
import signin from "../../Assets/our_providings/SigninBtn.png";
import { useMediaQuery } from "react-responsive";
const { Title, Text } = Typography;

const Slide = ({
  service,
  serviceCard,
  serviceDescription,
  serviceImage,
  serviceTitle,
  btn,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMediumscreen = useMediaQuery({ maxWidth: 768 });

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className={styles[serviceCard]}>
      <img
        // preview={false}
        src={service?.image}
        alt={service?.title}
        width={isMediumscreen ? 100 : 150}
        className={styles.serviceImage1}
      />
      <Title className={styles[serviceTitle]}>{service?.title}</Title>
      <Typography.Paragraph
        ellipsis={{ rows: 2 }}
        className={styles[serviceDescription]}
      >
        {service?.description}
      </Typography.Paragraph>
      {/* <Button className={styles[btn]} onClick={showModal}>More info</Button> */}
      <Modal
        closeIcon={true}
        centered={true}
        open={isModalOpen}
        onCancel={handleCancel}
        style={{ width: "100%", textAlign: "center" }}
        footer={null}
      >
        <Row>
          <Col span={24}>
            <Image preview={false} src={service?.petImage} alt="pet" />
          </Col>
          <Col span={24}>
            <img
              className={styles.serviceName}
              src={service?.serviceName}
              alt="pet"
            />
            <Text className={styles.serviceText}>{service?.serviceDesc}</Text>
            <br />
            <Image
              preview={false}
              className={styles.serviceBtn}
              style={{ cursor: "pointer" }}
              src={signin}
              alt="signin"
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default Slide;
