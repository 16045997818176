
import { startLoading, stopLoading } from "../../loadingstate/actions";
import { petsApi } from "../utils/api";
import { petMarketingConstants } from "./action-types";

const getPetMarketingSuccess = (data) => {
  return {
    type: petMarketingConstants.GET_ALL_PETS_SUCCESS,
    data: data,
  };
};

const getPetMarketingErr = (err) => {
  return {
    type: petMarketingConstants.GET_ALL_PETS_ERROR,
    valuesError: err,
  };
};

const getPetMarketingEvent = (page) => {
  return (dispatch) => {
    dispatch(startLoading());
    petsApi
      .getAllPets(page)
      .then((response) => {
        dispatch(getPetMarketingSuccess(response.data.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getPetMarketingErr(err?.response?.data?.message));
        dispatch(stopLoading());
      });
  };
};

const getPetByIdSuccess = (data) => {
  return {
    type: petMarketingConstants.GET_PET_BY_ID_SUCCESS,
    data: data,
  };
};

const getPetByIdErr = (err) => {
  return {
    type: petMarketingConstants.GET_PET_BY_ID_ERROR,
    valuesError: err,
  };
};

const getPetByIdEvent = (id) => {
  return (dispatch) => {
    dispatch(startLoading());
    petsApi
      .getPetById(id)
      .then((response) => {
        dispatch(getPetByIdSuccess(response.data.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getPetByIdErr(err?.response?.data?.message));
        dispatch(stopLoading());
      });
  };
};

export const petMarketingAction = {
  getPetMarketingEvent,
  getPetByIdEvent,
};
