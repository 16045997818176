import axios from "axios";

const apiURL = process.env.REACT_APP_DEPLOYED_URL;

// get Home Details
const getHomeDetails = () => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    let url = `${apiURL}/homeDetails`;
    // if (search) url += `?search=${search}`
    // let url = `${apiURL}/getAllHelp`
    return axios.get(url, config);
}

export const homeApi = {
    getHomeDetails
};