import React from "react";
import styles from "../../Common/Styles/common.module.css";
import { Card, Col, Row, Typography } from "antd";
import aboutUsstyles from "../Styles/static.module.css";
import GetInTouch from "../../Home/GetInTouch";
const { Text, Title } = Typography;

const AboutUs = () => {
    return (
        <div>
            <div style={{ background: "black" }} className={styles.navbarmenu}></div>
            <Row
                style={{ position: "relative" }}
                justify={"center"}
                className={aboutUsstyles.mainContent}
            >
                <div className={aboutUsstyles.overlayAboutUs}></div>
                <Col xs={22} sm={22} md={20} lg={16} style={{ textAlign: "center" }}>
                    <Title className={aboutUsstyles.aboutustitle} style={{color:'white'}}>
                        We take pride in offering pet services like no other
                    </Title>
                    <Text style={{color:'white'}} className={aboutUsstyles.aboutUsText}>
                        Our mission is clear: to offer a seamless, convenient, and
                        transparent services that prioritizes the safety and well-being of
                        every pet. We are dedicated to delivering exceptional care and
                        exceeding the expectations of pet owners.
                    </Text>
                </Col>
            </Row>
            <Row justify={"center"}>
                <Col
                    xs={22}
                    sm={20}
                    md={22}
                    lg={18}
                // style={{ position: "absolute", top: "80%", width: "100%" ,overflow:'scroll'}}
                >
                    <Card className={aboutUsstyles.cardStyle} hoverable style={{ position: "relative", top: "-5%" }}>
                        <Title
                            style={{ textAlign: "left", marginTop: 0 }}
                            className={aboutUsstyles.aboutustitle}
                        >
                            About Pets Mitra
                        </Title>
                        <Text className={aboutUsstyles.generalText}>
                            Welcome to Pets Mitra – Where Every Pet is Family!
                            <br /> At Pets Mitra, we understand that pets are more than just
                            animals; they are beloved members of your family. Our mission is
                            to provide exceptional care and services that ensure your furry
                            friends are happy, healthy, and well-loved when they are with us.
                        </Text>
                        <br />
                        <Title className={aboutUsstyles.title}>Our Story</Title>
                        <Text className={aboutUsstyles.generalText}>
                            Founded in 2024, Pets Mitra was born out of a passion for animals
                            and a commitment to pet parents who want the very best for their
                            pets. Our founder, [Founder’s Name], has [X years] of experience
                            in animal care and understands the unique needs of pets. With a
                            dedicated team of pet lovers, we strive to create a warm and
                            inviting environment where pets can thrive.
                        </Text>
                        <Title className={aboutUsstyles.title}>Our Services</Title>
                        <Text className={aboutUsstyles.generalText}>
                            We offer a comprehensive range of services to cater to all your
                            pet care needs:
                            <ul>
                                <li className={styles.generalText}>
                                    <span style={{ fontWeight: 600 }}>Pet Boarding:</span> Our
                                    comfortable and safe boarding facility is designed to make
                                    your pet feel at home. With spacious accommodations, plenty of
                                    playtime, and personalized attention, your pet will enjoy
                                    their stay while you’re away.
                                </li>
                                <li className={styles.generalText}>
                                    <span style={{ fontWeight: 600 }}>Pet Pickup And Drop:</span>{" "}
                                    We provide convenient pickup and drop-off services to make
                                    your life easier. Whether you need help getting your pet to
                                    our facility or back home, our reliable drivers ensure your
                                    pet travels safely and comfortably.
                                </li>
                                <li className={styles.generalText}>
                                    <span style={{ fontWeight: 600 }}>Pet Spa:</span> Treat your
                                    pet to a pampering session at our spa! We offer grooming
                                    services, baths, nail trimming, and more to keep your pet
                                    looking and feeling their best.
                                </li>
                                <li className={styles.generalText}>
                                    <span style={{ fontWeight: 600 }}>Pet Walking:</span> Our
                                    experienced dog walkers provide regular exercise and
                                    companionship for your furry friends. Whether it’s a quick
                                    stroll or an adventurous hike, we tailor our walks to meet
                                    your pet’s needs.
                                </li>
                                <li className={styles.generalText}>
                                    <span style={{ fontWeight: 600 }}>Pet Training:</span> Our
                                    professional trainers offer personalized training sessions to
                                    help your pet develop good behavior and obedience skills. From
                                    basic commands to advanced training, we use positive
                                    reinforcement techniques to create a strong bond between you
                                    and your pet.
                                </li>
                            </ul>
                        </Text>
                        <Title className={aboutUsstyles.title}>Our Values</Title>
                        <Text className={aboutUsstyles.generalText}>
                            At Pets Mitra, we are guided by our core values:
                            <ul>
                                <li className={styles.generalText}>
                                    <span style={{ fontWeight: 600 }}>Compassion:</span> We treat
                                    every pet with love and kindness, ensuring they feel safe and
                                    secure in our care.
                                </li>
                                <li className={styles.generalText}>
                                    <span style={{ fontWeight: 600 }}>Integrity:</span> We believe
                                    in transparency and honesty in all our interactions with pet
                                    parents and their furry companions.
                                </li>
                                <li className={styles.generalText}>
                                    <span style={{ fontWeight: 600 }}>Excellence:</span> Our
                                    commitment to providing high-quality services is unwavering.
                                    We continuously strive to improve and innovate to meet the
                                    needs of our clients.
                                </li>
                            </ul>
                        </Text>
                        <Title className={aboutUsstyles.title}>Meet Our Team</Title>
                        <Text className={aboutUsstyles.generalText}>
                            Our team is made up of certified pet care professionals who share
                            a deep love for animals. Each member is trained to handle pets
                            with care and respect, ensuring a positive experience for both
                            pets and their owners. We are always here to answer your questions
                            and provide support, whether you’re a first-time visitor or a
                            returning client.
                        </Text>
                        <Title className={aboutUsstyles.title}>Join Our Family!</Title>
                        <Text className={aboutUsstyles.generalText}>
                            We invite you to become part of the Pets Mitra family. Whether you
                            need a safe place for your pet to stay while you’re away, a
                            reliable dog walker, or a skilled trainer to help your pet learn
                            new skills, we’re here to help. Thank you for trusting us with
                            your beloved pets. We look forward to meeting you and your furry
                            friends! For more information about our services or to schedule a
                            visit, please contact us..
                        </Text>
                        <Title className={aboutUsstyles.title}>Contact Us</Title>
                        <Text style={{ display: "block" }} className={styles.generalText}>
                            PetsMitra
                        </Text>
                        <Text style={{ display: "block" }} className={styles.generalText}>
                            28/2A, Shakuntala Kanada Pa Haveli
                        </Text>
                        <Text style={{ display: "block" }} className={styles.generalText}>
                            Undri City,
                        </Text>
                        <Text style={{ display: "block" }} className={styles.generalText}>
                            Pune,
                        </Text>
                        <Text style={{ display: "block" }} className={styles.generalText}>
                            Maharashtra.
                        </Text>
                        <Text style={{ display: "block" }} className={styles.generalText}>
                            (+91) 8483-089-257
                        </Text>
                        <Text style={{ display: "block" }} className={styles.generalText}>
                            info@petsmitra.com
                        </Text>
                        <br />
                        <br />
                        <Text style={{ display: "block" }} className={styles.generalText}>
                            Last updated: Oct 29, 2024
                        </Text>
                    </Card>
                </Col>
            </Row>
            {/* <GetInTouch /> */}
        </div>
    );
};

export default AboutUs;
