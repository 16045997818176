import React from 'react';
import disclaimerImage from '../Assets/static/disclaimer.png';
import { Image, Typography } from 'antd';
import styles from './Styles/static.module.css';
const { Text, Title } = Typography;

const Disclaimer = () => {
    return (
        <>
            <div style={{ position: 'relative' }}>
                <Image
                    src={disclaimerImage}
                    alt="Privacy_policy"
                    className={styles.image}
                    preview={false}
                />
                <div className={styles.overlay}>
                    <Text style={{ display: "block" }} className={styles.text}>
                        All opinions expressed are solely those of the authors, not endorsed.
                    </Text>
                </div>
            </div>
            <div style={{ margin: "3rem" }}>
                <Text className={styles.generalText}>
                    By accessing or using the services provided by [Your Company Name] ("we," "us," or "our"), including pet boarding, pet pickup and drop, pet spa, pet walking, and pet training services, you acknowledge and agree to the terms outlined in this Disclaimer. If you do not agree to these terms, please refrain from using our services.
                </Text><br />
                <Title className={styles.title}>General Information</Title>
                <Text className={styles.generalText}>The information and services provided by [Your Company Name] on this website are for general information and operational purposes only. While we strive to provide accurate and up-to-date information, we make no representations or warranties, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information on our website or services.
                </Text>

                <Title className={styles.title}>Pet Care and Well-Being</Title>
                <Text className={styles.generalText}>Our team of professionals takes great care to ensure the safety, health, and well-being of your pet during the provision of our services. However, you acknowledge and agree that:
                </Text>
                <ul>
                    <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Health and Behavior Risks:</span> Pet services such as boarding, walking, spa treatments, and training involve some inherent risks. We cannot be held responsible for any health issues (e.g., stress, injury, illness) that may arise due to the pet’s pre-existing conditions, age, or behavior.
                    </li>
                    <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Vaccination Requirements:</span> It is your responsibility to ensure your pet is up-to-date on vaccinations before receiving our services. We may require proof of vaccinations for specific services, but we are not liable for any illness resulting from unvaccinated pets.
                    </li>
                    <li className={styles.generalText}><span style={{ fontWeight: 600 }}>Emergency Medical Treatment:</span> In the case of an emergency, we will make every effort to contact you. If you cannot be reached, we reserve the right to seek emergency veterinary care at your expense.
                    </li>
                </ul>
                <Title className={styles.title}>Limitation of Liability</Title>
                <Text className={styles.generalText}>[Your Company Name] shall not be held responsible or liable for:
                </Text>
                <ul>
                    <li className={styles.generalText}>Any injuries, accidents, or illnesses sustained by your pet while in our care unless such incidents are the direct result of gross negligence or willful misconduct by our staff.
                    </li>
                    <li className={styles.generalText}>Any loss or damage to personal property during the provision of services.
                    </li>
                    <li className={styles.generalText}>Delays, cancellations, or disruptions in service due to unforeseen circumstances, including weather conditions, natural disasters, or any other events beyond our control.
                    </li>
                </ul>
                <Text className={styles.generalText}>You acknowledge that pet care services may involve risks, and you agree to assume all risks associated with these services.
                </Text>
                <Title className={styles.title}>Third-Party Service Providers</Title>
                <Text className={styles.generalText}>Some of our services may involve third-party providers, such as veterinarians, groomers, or trainers. We do not control the actions or practices of these third-party providers, and we are not liable for any harm, injury, or loss that may result from the services provided by third parties. We recommend you review their terms, conditions, and policies separately.
                </Text>
                <Title className={styles.title}>Changes to Services and Pricing</Title>
                <Text className={styles.generalText}>We reserve the right to modify or discontinue any of our services at any time without prior notice. Prices for our services are subject to change without notice. While we make efforts to inform customers of pricing changes, we are not obligated to do so.
                </Text>
                <Title className={styles.title}>Website Content</Title>
                <Text className={styles.generalText}>The content of this website, including but not limited to text, graphics, logos, images, and information, is owned or licensed by [Your Company Name]. Any unauthorized use of the content on this website is strictly prohibited.
                </Text>
                <Title className={styles.title}>No Guarantees</Title>
                <Text className={styles.generalText}>While we strive to provide high-quality services, we cannot guarantee specific results, especially for pet training or behavioral modification services. Each pet is unique, and outcomes may vary based on the pet's temperament, age, and individual circumstances.
                </Text>
                <Title className={styles.title}>Indemnification</Title>
                <Text className={styles.generalText}>You agree to indemnify and hold harmless [Your Company Name], its employees, agents, and affiliates from any claims, damages, or expenses (including legal fees) that arise from your use of our services, breach of this Disclaimer, or any negligence on your part in providing accurate information about your pet.
                </Text>
                <Title className={styles.title}>Governing Law</Title>
                <Text className={styles.generalText}>This Disclaimer shall be governed by and construed in accordance with the laws of [Insert Jurisdiction]. Any disputes arising from the use of our services or website shall be subject to the exclusive jurisdiction of the courts in [Insert Jurisdiction].
                </Text>
                <Title className={styles.title}>Contact Us</Title>
                <Text className={styles.generalText}>If you have any questions or concerns about this Disclaimer, please contact us at:
                </Text>
                <Text style={{ display: "block" }} className={styles.generalText}>PetsMitra
                </Text>
                <Text style={{ display: "block" }} className={styles.generalText}>28/2A, Shakuntala Kanada Pa Haveli
                </Text>
                <Text style={{ display: "block" }} className={styles.generalText}>Undri City,
                </Text>
                <Text style={{ display: "block" }} className={styles.generalText}>Pune,
                </Text>
                <Text style={{ display: "block" }} className={styles.generalText}>Maharashtra.
                </Text>
                <Text style={{ display: "block" }} className={styles.generalText}>(+91) 8483-089-257
                </Text>
                <Text style={{ display: "block" }} className={styles.generalText}>info@petsmitra.com
                </Text>
                <br />
                <br />
                <Text style={{ display: "block" }} className={styles.generalText}>Last updated: Oct 24, 2024
                </Text>

            </div>
        </>
    )
}

export default Disclaimer
