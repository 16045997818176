import React from "react";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { Col, Divider, Image, Layout, Row, Space, Typography } from "antd";
import footerLogo from "../Assets/Footer/footer_logo.png";
import facebookicon from "../Assets/Footer/ic_baseline-facebook.png";
import instaicon from "../Assets/Footer/ri_instagram-fill.png";
import twwittericon from "../Assets/Footer/iconoir_x.png";
import youtubeicon from "../Assets/Footer/mdi_youtube.png";
import linkedinicon from "../Assets/Footer/mdi_linkedin.png";
import styles from "./Styles/footer.module.css";
import { Link } from "react-router-dom";

const { Text } = Typography;
const { Footer } = Layout;

const FooterComp = () => {
  const isLargeScreen = useMediaQuery({ minWidth: 992 });
  const isMediumScreen = useMediaQuery({ maxWidth: 992, minWidth: 769 });
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const isxsmallScreen = useMediaQuery({ maxWidth: 576 });
  const footerDetails = [
    {
      title: "Quick Access",
      key: "quickAccess",
      subsection: [
        {
          name: "Contact Us",
          key: "contactUs",
          navigateTo: "contactUs"
        },
        {
          name: "Pets",
          key: "pets",
          navigateTo: "pets",
        },
        {
          name: "About Us",
          key: "aboutUs",
          navigateTo: 'aboutUs'
        },
        {
          name: "News & Blogs",
          key: "news",
          path: "#blogs",
        },
        {
          name: "FAQ's",
          key: "faqs",
          navigateTo: "faqs"
        },
      ],
    },
    {
      title: "For Enquiry",
      key: "forEnquiry",
      subsection: [
        {
          name: "(+91) 8483-089-257",
          key: "phone1",
          icon: <FaPhoneAlt />,
        },
        {
          name: "info@petsmitra.com",
          key: "mail",
          icon: <MdEmail />,
        },
      ],
    },
    {
      title: "Terms & Conditions",
      key: "Terms&Conditions",
      subsection: [
        {
          name: "Privacy Policy",
          key: "PrivacyPolicy",
          navigateTo: "privacyPolicy"
        },
        {
          name: "Terms of Use",
          key: "termsOfUse",
          navigateTo: "termsOfUse"
        },
        {
          name: "Reservation Policy",
          key: "ReservationPolicy",
          navigateTo: "reservationPolicy"
        },
        {
          name: "Refund & Cancellation Policy",
          key: "RefundPolicy",
          navigateTo: "refundCancellationPolicy"
        },
        {
          name: "Disclaimer",
          key: "disclaimer",
          navigateTo: "disclaimer"
        },

      ],
    },
  ];
  const smallScreenFooterDetails = [
    {
      title: "Quick Access",
      key: "quickaccessmobile",
      subsection: [
        {
          name: "Contact Us",
          key: "contactusmobile",
          navigateTo: "contactUs"
        },
        {
          name: "Pets",
          key: "petsmobile",
          navigateTo: "pets",
        },
        {
          name: "About Us",
          key: "aboutusMobile",
          navigateTo: 'aboutUs'
        },
        {
          name: "News & Blogs",
          key: "newsmobile",
          path: "#blogs",
        },
        {
          name: "Reservation Policy",
          key: "reservationpolicymobile",
          navigateTo: "reservationPolicy"
        },
        {
          name: "Privacy Policy",
          key: "privacypolicymobile",
          navigateTo: "privacyPolicy"
        },
        {
          name: "Terms of Use",
          key: "termsAndConditionsmobile",
          navigateTo: "termsOfUse"
        },
        {
          name: "Refund & Cancellation Policy",
          key: "refundpolicymobile",
          navigateTo: "refundCancellationPolicy"
        },
        {
          name: "Disclaimer",
          key: "disclaimermobile",
          navigateTo: "disclaimer"
        },
      ],
    },
    {
      title: "For Enquiry",
      key: "enquirymobile",
      subsection: [
        {
          name: "(+91) 8483-089-257",
          key: "phone1mobile",
          icon: <FaPhoneAlt />,
        },
        {
          name: "info@petsmitra.com",
          key: "mailmobile",
          icon: <MdEmail />,
        },
      ],
    },
  ];
  const socialAccounts = [
    {
      image: facebookicon,
      key: "facebook",
      to: "https://www.facebook.com/profile.php?id=61567145026713"
    },
    {
      image: twwittericon,
      key: "twwitter",
      to: "https://x.com/myPetsMitra"
    },
    {
      image: youtubeicon,
      key: "youtube",
      to: "https://www.youtube.com/@myPetsMitra"
    },
    {
      image: instaicon,
      key: "insta",
      to: 'https://www.instagram.com/mypetsmitra'
    },
    {
      image: linkedinicon,
      key: "linkedin",
      to: "https://www.linkedin.com/company/mypetsmitra"
    },
  ];
  return (
    <>
      <Footer className={styles.footerStyle}>
        {(isLargeScreen || isMediumScreen) && (
          <>
            <Row gutter={[[16, { xs: 8, sm: 16, md: 24, lg: 32 }]]}>
              <Col
                md={6}
                lg={2}
                xl={2}
                xxl={2}
                style={{ margin: "auto", display: "block" }}
              >
                <Image
                  src={footerLogo}
                  alt="Logo"
                  preview={false}
                  className={styles.logo}
                />
              </Col>
              {isMediumScreen && (
                <>
                  <Col md={16} style={{ display: "block", margin: "auto" }}>
                    <div className={styles.locationText}>
                      <FaLocationDot /> 28/2A, Shakuntala Kanada Pa Haveli,
                      Undari City,
                      <br />
                      Pune, Maharashtra.
                    </div>
                  </Col>
                  <Divider style={{ backgroundColor: "white" }} />
                </>
              )}
              {footerDetails?.map((section) => (
                <Col
                  key={section?.key}
                  md={{ span: 8 }}
                  lg={{ span: 5 }}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "center",
                    margin: section?.title === "Quick Access" ? 0 : "",
                  }}
                >
                  <Space direction="vertical" size="middle">
                    <div className={styles.sectionTitle}>{section?.title}</div>
                    {section?.subsection?.map((page) => (
                      page?.path ?
                        <a href={page?.path} key={page?.key}>
                          <div className={styles.pageName}>
                            {page?.icon}&nbsp;{page?.name}
                          </div>
                        </a> :
                        <Link className={styles.pageName} key={page?.key} to={page?.navigateTo}>
                          {page?.icon}&nbsp;{page?.name}
                        </Link>
                    ))}
                  </Space>
                </Col>
              ))}
              {!isMediumScreen && (
                <Col lg={{ span: 5, offset: 1 }} className={styles.location}>
                  <Space direction="vertical" size={"middle"}>
                    <div className={styles.sectionTitle}>Our location</div>
                    <div className={styles.locationText}>
                      <FaLocationDot /> 28/2A,
                      <br />
                      Shakuntala Kanada Pa Haveli,
                      <br />
                      Undari City,
                      <br />
                      Pune,
                      <br />
                      Maharashtra.
                    </div>
                  </Space>
                </Col>
              )}
            </Row>
          </>
        )}
        {isSmallScreen && (
          <>
            <Row>
              <Col xs={24} sm={4} style={{ margin: "auto", display: "block" }}>
                <Image
                  width={"100%"}
                  height="auto"
                  src={footerLogo}
                  alt="Logo"
                  preview={false}
                  className={styles.logo}
                />
              </Col>
              <Col xs={24} sm={20} style={{ display: "block", margin: "auto" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className={styles.locationText}>
                    <FaLocationDot /> 28/2A, Shakuntala Kanada Pa Haveli, Undari
                    City,
                    <br />
                    Pune, Maharashtra.
                  </div>
                </div>
              </Col>
              <Divider style={{ backgroundColor: "white" }} />
            </Row>
            <Row>
              {smallScreenFooterDetails?.map((section) => (
                <Col
                  key={section?.key}
                  xs={24}
                  sm={12}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "center",
                    margin: section?.title === "Quick Access" ? 0 : "",
                  }}
                >
                  <Space direction="vertical" size="middle">
                    <div className={styles.sectionTitle}>{section?.title}</div>
                    {section?.subsection?.map((page) => (
                      page?.path ?
                        <a key={page?.key} href={page?.path}>
                          <div className={styles.pageName}>
                            {page?.icon}&nbsp;{page?.name}
                          </div>
                        </a> :
                        <Link className={styles.pageName} to={page?.navigateTo} key={page?.key}>
                          {page?.icon}&nbsp;{page?.name}
                        </Link>
                    ))}
                    {isxsmallScreen && (
                      <Divider style={{ backgroundColor: "white" }} />
                    )}
                  </Space>
                </Col>
              ))}
            </Row>
          </>
        )}
        <Divider className={styles.divider} />
        <div
          style={{
            display: "flex",
            justifyContent: isxsmallScreen ? "center" : "space-between",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Text className={styles.copyright}>
            Copyright&#169;2024. Made with by Team Pragmasoft
          </Text>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "25%",
            }}
          >
            {socialAccounts?.map((account) => (
              <Link to={account?.to} key={account?.key}>
                <Image
                  width={30}
                  src={account?.image}
                  preview={false}
                />
              </Link>
            ))}
          </div>
        </div>
      </Footer>
    </>
  );
};

export { FooterComp };
