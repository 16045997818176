import axios from "axios";

const apiURL = process.env.REACT_APP_DEPLOYED_URL
// const apiURL = 'https://api.qa.petsmitra.com/v1'
// get all blogs
const getAllBlogs = (page) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/newsMarketing?page=${page}&limit=16`, config);
}

// get blog based on id
const getBlogById = (id) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    return axios.get(`${apiURL}/news/${id}`, config);
}


export const newsApi = {
    getAllBlogs,
    getBlogById
};