let homeSection={
    firstSection:{
        marathi:"हे सर्व आमच्या प्रेमळ मित्रांची काळजी घेण्याबद्दल आहे",
        English:"It's all about caring our furry friends",
        telugu:"ఇది మన బొచ్చుగల స్నేహితుల పట్ల శ్రద్ధ వహించడం",
        bengal:"এটা আমাদের পশম বন্ধুদের জন্য যত্ন সম্পর্কে সব"
    }
}

let language=[{label:"marathi",value:"marathi"},{label:"English",value:"English"},{label:"telugu",value:"telugu"},{label:"bengal",value:"bengal"}]
module.exports={homeSection,language}