import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, ArrowRightOutlined, SmileOutlined } from "@ant-design/icons";
const NotFound = ({
  status,
  title,
  subTitle,
  continueTo,
  icon = <SmileOutlined />,
  buttonName = "Back",
  secondButton,
  secondBtnContinueTo,
  buttonIcon1 = <ArrowLeftOutlined />,
  buttonIcon2 = <ArrowRightOutlined />
}) => {
  const navigate = useNavigate();
  return (
    <Result
      icon={icon}
      status={status}
      title={title}
      subTitle={subTitle}
      extra={
        <>
          <Button
            type="primary"
            size="small"
            onClick={() => navigate(continueTo)}
            icon={buttonIcon1}
          >
            {buttonName}
          </Button>
          {secondButton && (
            <Button
              type="primary"
              size="small"
              iconPosition="end"
              onClick={() => navigate(secondBtnContinueTo)}
              icon={buttonIcon2}
            >
              {secondButton}
            </Button>
          )}
        </>
      }
    />
  );
};
export { NotFound };
