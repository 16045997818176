import { commonApi } from "../Common/utils/api";
import { appconstants } from "./action-types";

const AddContactUsModalError = (err) => {
  return {
    type: appconstants.ADD_CONTACT_DATA_FAILURE,
    magazineDataError: err,
  };
};

const AddContactUsModalSuccess = (data) => {
  return {
    type: appconstants.ADD_CONTACT_DATA_SUCCESS,
    contactUsData: data,
  };
};

const AddContactUsModalData = (data, source) => {
  return (dispatch) => {
    commonApi
      .addDataRequest(data, source)
      .then((response) => {
        dispatch(AddContactUsModalSuccess(response.data.info));
      })
      .catch((err) => {
        dispatch(AddContactUsModalError(err));
      });
  };
};

export const appActions = {
  AddContactUsModalData,
};
