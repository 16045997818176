import React, { useState } from "react";
import { Col, Image, Layout, Row, Typography } from "antd";
import { useMediaQuery } from "react-responsive";
// import onboardImage from "../../Assets/onBoard.png";
// import signInButton from "../../Assets/signIn.png";
// import onBoardMobile from "../../Assets/onBoardMobile.png";
import image1 from "../../Assets/SecondSection/secondSection.png";
import details from "../../Assets/SecondSection/details.png";
import final from "../../Assets/SecondSection/final.png";
import image2 from "../../Assets/SecondSection/2.png";
import image3 from "../../Assets/SecondSection/3.png";
import styles from "../Styles/onBoard.module.css";

const { Content } = Layout;
const { Text } = Typography;

const OnBoard = () => {
  const isLargeScreen = useMediaQuery({ maxWidth: 992 });
  const [styleBg, setStyleBg] = useState("1");

  const handleClick = (e, num) => {
    setStyleBg(num);
  };

  return (
    <Content id="onBoard" className={styles.onboardContent}>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Row gutter={[16, 16]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className={styleBg === "1" ? styles.onSelect : styles.onUnSelect}
              style={{
                borderTopRightRadius: "12px",
                borderBottomRightRadius: "12px",
                cursor: "pointer",
              }}
              onClick={(e) => handleClick(e, "1")}
            >
              <Row className={styles.numberRow} gutter={[16, 16]}>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <div className={styles.numberContainer}>
                    <Text className={styles.number}>1</Text>
                  </div>
                </Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                  <div className={styles.details}>
                    <Text
                      style={{ display: "block" }}
                      className={styles.text_1}
                    >
                      Select required service
                    </Text>
                    <Text
                      style={{ display: "block" }}
                      className={styles.text_2}
                    >
                      Choose our premium pet care service for dedicated
                      attention, expert care, & joyful experiences for your
                      beloved furry companions.
                    </Text>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className={styleBg === "2" ? styles.onSelect : styles.onUnSelect}
              style={{
                borderTopRightRadius: "12px",
                borderBottomRightRadius: "12px",
                cursor: "pointer",
              }}
              onClick={(e) => handleClick(e, "2")}
            >
              <Row
                style={{
                  paddingTop: "25px",
                  paddingRight: "25px",
                  paddingBottom: "25px",
                  paddingLeft: "10px",
                }}
                gutter={[16, 16]}
              >
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <div className={styles.numberContainer}>
                    <Text className={styles.number}>2</Text>
                  </div>
                </Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                  <div className={styles.details}>
                    <Text
                      style={{ display: "block" }}
                      className={styles.text_1}
                    >
                      Enter the required details
                    </Text>
                    <Text
                      style={{ display: "block" }}
                      className={styles.text_2}
                    >
                      Enter the necessary details, such as the date, time,
                      preferred slot, pickup address, and drop address.
                    </Text>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className={styleBg === "3" ? styles.onSelect : styles.onUnSelect}
              onClick={(e) => handleClick(e, "3")}
              style={{
                borderTopRightRadius: "12px",
                borderBottomRightRadius: "12px",
                cursor: "pointer",
              }}
            >
              <Row
                style={{
                  paddingTop: "25px",
                  paddingRight: "25px",
                  paddingBottom: "25px",
                  paddingLeft: "10px",
                }}
                gutter={[16, 16]}
              >
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <div className={styles.numberContainer}>
                    <Text className={styles.number}>3</Text>
                  </div>
                </Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                  <div className={styles.details}>
                    <Text
                      style={{ display: "block" }}
                      className={styles.text_1}
                    >
                      Book appointment
                    </Text>
                    <Text
                      style={{ display: "block" }}
                      className={styles.text_2}
                    >
                      Book your pet's service appointment now! Ensure their
                      happiness with our expert care.
                    </Text>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {!isLargeScreen ? (
          <Col lg={12} xl={12} xxl={12}>
            <div className={styles.imageContainer}>
              <Image
                src={
                  styleBg === "1" ? image2 : styleBg === "2" ? details : final
                }
                preview={false}
                alt="second_section_image"
                style={{ transition: "all .4s ease-out" }}
                className={styles.image}
              />
            </div>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </Content>
  );
};

export default OnBoard;
