import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newsAction } from "../State/actions";
import { useParams } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import styles from "../../Common/Styles/common.module.css";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

const NewsDescription = () => {
  const { id } = useParams();
  const { isLoading } = useSelector((str) => str.loadingReducer);
  const { t } = useTranslation(id);

  if (isLoading) return <Spinner />;

  return (
    <div>
      <div style={{ background: "black" }} className={styles.navbarmenu}></div>
      <Row justify={"center"}>
        <Col xs={22} sm={20} md={18} lg={16}>
          <p
            className={styles.descImg}
            dangerouslySetInnerHTML={{
              __html: t(id)
              // getBlogById?.description?.["en"]
              // ?.[
              //   sessionStorage.getItem("locale") || "en"
              // ],
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default NewsDescription;
