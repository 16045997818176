import React, { useEffect, useState } from "react";
import { petsApi } from "./utils/api";
import Spinner from "../Spinner/Spinner";
import { Col, Empty, Row, Spin } from "antd";
import PetCard from "../Home/Components/PetCard";
import styles from "../Common/Styles/common.module.css";
import { useDispatch, useSelector } from "react-redux";
import { petMarketingAction } from "./State/actions";
import InfiniteScroll from "react-infinite-scroll-component";

const Pets = () => {
  const [page, setPage] = useState(1);
  const { isLoading } = useSelector((str) => str.loadingReducer);
  const dispatch = useDispatch();
  const { allPetsMarketing, totalPetsMarketing } = useSelector(
    (str) => str.petsMarketingReducer
  );

  useEffect(() => {
    dispatch(petMarketingAction.getPetMarketingEvent(1));
  }, []);

  // console.log(allPetsMarketing, 'allPetsMarketing')

  const loadMore = () => {
    if (allPetsMarketing?.length < totalPetsMarketing) {
      const nextPage = page + 1;
      dispatch(petMarketingAction.getPetMarketingEvent(nextPage));
      setPage(nextPage);
    }
  };

  if (isLoading && page === 1) return <Spinner />;

  return (
    <div id="scrollableDiv">
      <InfiniteScroll
        dataLength={allPetsMarketing && allPetsMarketing?.length}
        scrollableTarget="scrollableDiv"
        hasMore={allPetsMarketing?.length < totalPetsMarketing}
        next={loadMore}
        scrollThreshold={0.5}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        loader={
          isLoading && (
            <div style={{ textAlign: "center", padding: "50px" }}>
              <Spin />
            </div>
          )
        }
      >
        <div
          style={{ background: "black" }}
          className={styles.navbarmenu}
        ></div>
        <Row gutter={16} style={{ margin: "1.2em", justifyContent:'center' }}>
          {allPetsMarketing ? (
            allPetsMarketing?.map((pet) =>
              <Col xs={24} sm={12} md={10} lg={7} xl={6}>
                <PetCard pet={pet} />
              </Col>
            )
          ) : (
            <Empty />
          )}
        </Row>
      </InfiniteScroll>
    </div>
  );
};

export { Pets };
