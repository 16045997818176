import { helpConstants } from "./action-types";

const initialState = {
  allQuestionsForscreen: false,
  allQuestionsForscreenErr: false,
  totalQuestions: 0
};

const helpReducer = (state = initialState, action) => {
  switch (action.type) {
    case helpConstants.GET_QUESTIONS_KEY_PLATFORM_SUCCESS:
      return {
        ...state,
        allQuestionsForscreen: action.data.currentPage === 1
          ? action.data.docs
          : [...state.allQuestionsForscreen, ...action.data.docs],
        allQuestionsForscreenErr: false,
        totalQuestions: action.data.totalDocs
      }
    case helpConstants.GET_QUESTIONS_KEY_PLATFORM_ERROR:
      return {
        ...state,
        allQuestionsForscreen: false,
        allQuestionsForscreenErr: action.valuesError,
        totalQuestions: 0
      }
    default:
      return state;
  }
};
export { helpReducer };
