import {  navbarconstants } from "./action-types";

const initialState = {
  defaultLanguage: "English",
};

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case navbarconstants.CHANGE_LANGUAGE:
      return {
        ...state,
        defaultLanguage: action.defaultLanguage,
      };

    default:
      return state;
  }
};
export { navbarReducer };
